/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { CompletionMutationVariables } from '@/client/types/content/completion/ContentCompletion';
import type { ESignatureAgreementText } from '@/client/types/esignature/ESignatureAgreementText';
import SolidButton from '../shared/buttons/SolidButton';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useAuthStore } from '@/client/services/state/authStore';
import { useTranslation } from 'react-i18next';

interface SignatureModalProps {
  isOpen: boolean;
  onClose: () => void;
  messaging: ESignatureAgreementText;
  onCompleteContent: UseMutateAsyncFunction<any, unknown, CompletionMutationVariables, unknown>;
}

interface SignatureFormData {
  signature: string;
  acceptedTerms: boolean;
}

export default function SignatureModal({
  isOpen,
  onClose,
  messaging,
  onCompleteContent,
}: SignatureModalProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;

  const { full_name: fullName } = user;

  const methods = useForm<SignatureFormData>({
    defaultValues: {
      signature: '',
      acceptedTerms: false,
    },
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = methods;

  const { signature, acceptedTerms } = watch();

  const buttonIsDisabled =
    !!errors.signature || !!errors.acceptedTerms || signature === '' || !acceptedTerms;

  // * The modal will close once the query data has been reset from the onSuccess callback of the onCompleteContent mutation
  const onSubmit: SubmitHandler<SignatureFormData> = (data) =>
    onCompleteContent({ signature: data.signature, signedAgreementTextId: messaging._id });

  const onHandleClose = () => {
    reset();
    onClose();
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onHandleClose}>
      <ModalOverlay />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent padding={8}>
            <ModalBody>
              <Stack spacing={4}>
                <Text fontSize="18px" fontWeight={600}>
                  {t('esignature.modal.signatureRequiredForCompletion')}
                </Text>

                <Text
                  fontSize="14px"
                  fontWeight={400}
                  maxHeight={200}
                  overflowY="auto"
                  wordBreak="break-word"
                >
                  {messaging.text}
                </Text>

                <Text fontSize="18px" fontWeight={600}>
                  {t('esignature.modal.addSignature')}
                </Text>

                <Text fontSize="14px" fontWeight={400}>
                  {t('esignature.modal.fullName')} {fullName}
                </Text>

                <Controller
                  control={control}
                  name="signature"
                  rules={{
                    required: `${t('esignature.modal.form.error.mustMatchName')} ${fullName}`,
                    validate: (value) =>
                      value === fullName ||
                      `${t('esignature.modal.form.error.mustMatchName')} ${fullName}`,
                  }}
                  render={({ field }) => (
                    <FormControl isInvalid={!!errors.signature}>
                      <Input
                        {...field}
                        variant="create"
                        type="text"
                        placeholder={`${t('esignature.modal.form.name.placeholder')}`}
                        autoComplete="off"
                      />

                      <FormErrorMessage>{errors.signature?.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="acceptedTerms"
                  rules={{ required: `${t('esignature.modal.form.acceptedTerms.error')}` }}
                  render={({ field }) => (
                    <FormControl isInvalid={!!errors.acceptedTerms}>
                      <HStack alignItems="start">
                        <Checkbox
                          isChecked={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          marginTop={1}
                        />

                        <Text fontSize="14px" fontWeight={500}>
                          {t('esignature.modal.form.acceptedTerms.label')}
                        </Text>
                      </HStack>

                      <FormErrorMessage>{errors.acceptedTerms?.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Stack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button variant="cancel" onClick={onHandleClose}>
                  {t('global.actions.close')}
                </Button>

                <SolidButton
                  type="submit"
                  isDisabled={buttonIsDisabled}
                  onMouseOver={() => {
                    if (buttonIsDisabled) trigger();
                  }}
                  onClick={() => {}}
                >
                  {t('esignature.modal.form.signSave')}
                </SolidButton>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
}
