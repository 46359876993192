import {
  Badge,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useTheme,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import ContextService from '@/client/services/api/ContextService';
import Loading from '@/client/components/media/Loading';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

type ContentSelectorProps = {
  contentId: string | undefined;
  contentType: string;
  handleContextSelect: (selectedContextId: string) => Promise<void>;
  initialContextId: string | null;
  enabled: boolean;
  parentContextId?: string;
  contexts?: { key: string; value: string }[];
};

export default function ContextSelector({
  contentType,
  contentId,
  handleContextSelect,
  initialContextId,
  enabled,
  parentContextId = undefined,
  contexts = [],
}: ContentSelectorProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [contextSelected, setContextSelected] = useState(false);

  function getCompletionStatusColor(dueDate: Date) {
    return !dueDate || new Date(dueDate) > new Date() ? '#F7BD4B' : '#EA6153';
  }
  function getCompletionStatusCopy(dueDate: Date) {
    return !dueDate || new Date(dueDate) > new Date()
      ? t('recompletions.contextSelector.onTime')
      : t('recompletions.contextSelector.pastDue');
  }

  const { isFetching: isFetchingContexts, data: contextChoices } = useQuery({
    enabled: enabled && !initialContextId,
    queryKey: ['contexts', { contentId, contentType }],
    cacheTime: 0,
    queryFn: () => ContextService.getContextChoices(contentType, contentId),
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const contextChoicesAvailable = !!contextChoices && contextChoices.length > 0;

  const { isLoading: isLoadingContextId, data: contextSelectedDeprecated } = useQuery({
    enabled: !enabled || (!isFetchingContexts && !contextChoicesAvailable),
    queryKey: ['context-id', { contentId, contentType, contexts, parentContextId, initialContextId }],
    cacheTime: 0,
    queryFn: () =>
      ContextService.getOrCreateContext(contentType, contentId, contexts, parentContextId, initialContextId),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let contextIdToImmediatelySelect;

    if (initialContextId) {
      contextIdToImmediatelySelect = initialContextId;
    } else if (contextChoicesAvailable && contextChoices.length === 1) {
      contextIdToImmediatelySelect = contextChoices[0].contextId;
    } else if (contextSelectedDeprecated) {
      // needed until all contexts we support are selectable (i.e. tracks, journeys, assignments)
      // tracks and journeys assume only one context is ever available
      contextIdToImmediatelySelect = contextSelectedDeprecated;
    }

    if (contextIdToImmediatelySelect) {
      handleContextSelect(contextIdToImmediatelySelect);
      setContextSelected(true);
    }
  }, [contextChoices, initialContextId, contextSelectedDeprecated]);

  if ((enabled && isFetchingContexts) || (!enabled && isLoadingContextId && !initialContextId))
    return <Loading />;

  return (
    enabled &&
    !initialContextId &&
    contextChoices && (
      <Modal
        isOpen={contextChoices.length > 1 && !contextSelected}
        isCentered
        size="3xl"
        closeOnOverlayClick={false}
        onClose={() => {}}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalBody>
            <Box flex={1} padding={10} textAlign="center">
              <Text>{t('contextSelect.headerIncomplete')}</Text>

              <br />

              <Text>{t('contextSelect.headerSelect')}</Text>

              <br />

              <TableContainer>
                <Box maxHeight="212px" overflowY={contextChoices.length > 3 ? 'scroll' : 'hidden'}>
                  <Table>
                    <Thead position="sticky" top="0" backgroundColor="#FFFFFF">
                      <Tr>
                        <Td
                          textAlign="center"
                          display={{ lg: 'table-cell' }}
                          fontWeight="700"
                          width={{ base: '100%', lg: 'auto' }}
                        >
                          {t('global.forms.labels_dateAssigned')}
                        </Td>

                        <Td
                          textAlign="center"
                          display={{ lg: 'table-cell' }}
                          fontWeight="700"
                          width={{ base: '100%', lg: 'auto' }}
                        >
                          {t('global.forms.labels_dueDate')}
                        </Td>

                        <Td
                          textAlign="center"
                          display={{ lg: 'table-cell' }}
                          fontWeight="700"
                          width={{ base: '100%', lg: 'auto' }}
                        >
                          {t('global.forms.labels_percentComplete')}
                        </Td>

                        <Td
                          textAlign="center"
                          display={{ lg: 'table-cell' }}
                          fontWeight="700"
                          width={{ base: '100%', lg: 'auto' }}
                        >
                          {t('global.forms.labels_status')}
                        </Td>
                      </Tr>
                    </Thead>

                    <Tbody
                      backgroundColor="rgb(247,247,247)"
                      color={theme.colors.brand.primary}
                      data-testid="context-table"
                    >
                      {contextChoices.map((choice) => (
                        <Tr
                          key={choice.contextId}
                          onClick={async () => {
                            await handleContextSelect(choice.contextId);
                            setContextSelected(true);
                          }}
                          _hover={{ cursor: 'pointer' }}
                        >
                          <Td textAlign="center">{format(new Date(choice.creationDate), 'P')}</Td>

                          <Td textAlign="center">
                            {choice.contextDueDate
                              ? format(new Date(choice.contextDueDate), 'P')
                              : '--'}
                          </Td>

                          <Td textAlign="center">{choice.contextPercentCompleted + '%'}</Td>

                          <Td textAlign="center">
                            <Tooltip
                              hasArrow
                              zIndex={9999}
                              label={getCompletionStatusCopy(choice.contextDueDate)}
                            >
                              <span>
                                <Badge
                                  backgroundColor={getCompletionStatusColor(choice.contextDueDate)}
                                  borderRadius="50%"
                                  height="0.75rem"
                                  width="0.75rem"
                                />
                              </span>
                            </Tooltip>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
              </TableContainer>

              <Button
                marginY={4}
                paddingY={6}
                paddingX={12}
                textAlign="center"
                fontWeight="400"
                backgroundColor="#D9D9D9"
                color="black"
                _hover={{
                  backgroundColor: '#CACBCD',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/explore')}
              >
                {t('global.actions.close')}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
}
