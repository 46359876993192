import {
  Avatar,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
  useToken,
} from '@chakra-ui/react';
import { Mention, MentionsInput } from 'react-mentions';
import { QuestionCircleFilled, Share } from '@/client/components/icons/ContinuIcons';
import { useMutation, useQuery } from '@tanstack/react-query';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import { SearchService } from '@/client/services/api/SearchService';
import ShareService from '@/client/services/api/SharesService';
import { capCase } from '@/client/utils/capCase';
import { formatMessage } from '@/client/utils/content/shares/formatMessage';
import sharesInputStyles from './sharesInputStyles';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useAuthStore } from '@/client/services/state/authStore';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import { useState } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';

interface HeaderSharesIconProps {
  contentType: string;
  contentId: string | undefined;
  lightIcons?: boolean;
}

export default function HeaderSharesIcon({
  contentType,
  contentId,
  lightIcons = false,
}: HeaderSharesIconProps) {
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { partner } = usePartnerStore();
  const { setToast } = useToastStore();
  const [value, setValue] = useState('');
  const [comment, setComment] = useState('');
  const [recipients, setRecipients] = useState<string[]>([]);
  const [mentionColor] = useToken('colors', ['brand.gray.100']);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const displayName = contentType === 'imported_content' ? 'course' : capCase(contentType);

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: [`${contentType}-stats`, { contentId }],
    queryFn: () => AnalyzeService.getContentStats(contentId),
  });

  const handleReset = () => {
    setValue('');
    setRecipients([]);
    onClose();
  };

  const shareContent = useMutation({
    mutationFn: () =>
      ShareService.shareContent(user._id, contentType, contentId, comment, recipients),
    onSuccess: () => {
      trackEvent(contentType + '_shared');
      recipients.forEach((recipient) =>
        AnalyzeService.postShares(contentId, contentType, recipient),
      );
      setToast({
        show: true,
        status: 'success',
        title: `The ${displayName} was shared!`,
      });
      handleReset();
      refetch();
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: `The ${displayName} could not be shared.`,
      }),
  });

  // Start search filtering work HERE
  const fetchTaggableUsers = async (query: string, callback: any) => {
    if (!query || query.length < 3) return;

    const partnerId = partner._id || null;

    const users = await SearchService.searchAllUsers(query, partnerId);

    const formattedUsers = users.map((u: any) => ({
      display: u.full_name,
      id: u._id,
    }));

    return callback(formattedUsers);
  };

  const handleMentionsChange = (str: string) => {
    const formatted = formatMessage(str);

    setValue(str);
    setRecipients(formatted.recipients);
    setComment(formatted.message);
  };

  if (isLoading || isError) return null;

  return (
    <Flex alignItems="center">
      <Popover isOpen={isOpen}>
        <PopoverTrigger>
          <IconButton
            aria-label="Share this content"
            variant="ghost"
            icon={
              <Share
                color={lightIcons ? 'white' : 'brand.iconGrey'}
                boxSize={6}
                _hover={{ color: 'brand.button' }}
              />
            }
            onClick={onToggle}
          />
        </PopoverTrigger>

        <PopoverContent width={{ base: '100vw', md: 'lg' }}>
          <PopoverCloseButton onClick={() => onClose()} />

          <PopoverBody marginTop={6}>
            <HStack spacing={6}>
              <Avatar size="md" name={user.full_name} src={user.image} />

              <MentionsInput
                // @ts-ignore
                style={{ ...sharesInputStyles, width: '100%' }}
                value={value}
                placeholder="@mention a colleague to share this"
                onChange={(e) => handleMentionsChange(e.target.value)}
              >
                <Mention
                  trigger="@"
                  data={fetchTaggableUsers}
                  appendSpaceOnAdd
                  style={{ backgroundColor: mentionColor, borderRadius: '4px' }}
                  markup="<:__display__:__id__>"
                  displayTransform={(id, display) => `@${display}`}
                />
              </MentionsInput>

              <Tooltip label="Help me with this" placement="bottom-start" hasArrow>
                <span>
                  <Link
                    isExternal
                    href="https://help.continu.com/hc/en-us/articles/360026149754-Share-Content"
                    target="_blank"
                  >
                    <QuestionCircleFilled boxSize={5} color="blackAlpha.600" />
                  </Link>
                </span>
              </Tooltip>
            </HStack>
          </PopoverBody>

          {recipients.length >= 1 && (
            <PopoverFooter>
              <ButtonGroup width="full" justifyContent="flex-end">
                <Button variant="cancel" onClick={() => handleReset()}>
                  Cancel
                </Button>

                <Button
                  isLoading={shareContent.status === 'loading'}
                  onClick={() => shareContent.mutate()}
                >
                  Share
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          )}
        </PopoverContent>
      </Popover>

      {data.data[0].shares > 0 && (
        <Text textColor={lightIcons ? 'white' : 'black'}>{data.data[0].shares}</Text>
      )}
    </Flex>
  );
}
