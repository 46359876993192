import { graphql } from '../../gql/gql';

// IMPORTANT: Build gql after ANY changes to queries (client OR api)

export const getContentSatisfiedQuery: any = graphql(`
  query GetUserContentFromContextId($contentType: ContentTypes!, $contextId: String!) {
    getUserContent(contentType: $contentType, contextId: $contextId) {
      satisfied
    }
  }
`);
