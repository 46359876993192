import CreateFormGroup from '../../../shared/layout/CreateFormGroup';
import CreateSwitchContainer from '../../../shared/layout/CreateSwitchContainer';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function TrackSettingsForm() {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <CreateFormGroup>
        <CreateSwitchContainer
          name="sequential"
          label={t('edit.track.lockCompletionSequence')}
          helpText={t('edit.track.tips_copyLockSequence')}
        />
      </CreateFormGroup>

      <CreateFormGroup>
        <CreateSwitchContainer
          name="resource"
          label={t('edit.tracks.resource')}
          helpText={t('edit.tracks.resource_text')}
        />
      </CreateFormGroup>
    </Stack>
  );
}
