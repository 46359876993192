/* eslint-disable react/jsx-props-no-spreading */
import { AiPromptResponse, ContentTypes } from '@/client/services/api/graphql/gql/graphql';
import {
  ArrowLeft,
  ArrowRight,
  Conference,
  Feedback,
  Money,
} from '@/client/components/icons/ContinuIcons';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Container,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Navigate, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { PersistOptions, persist } from 'zustand/middleware';
import { StateCreator, create } from 'zustand';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';

import { PiDotOutlineFill } from 'react-icons/pi';
import { getPromptResponseQuery } from './api/getPromptResponseQuery';
import { graphQLClient } from '@/client/services/api/clients/graphqlClient';
import { useAuthStore } from '@/client/services/state/authStore';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use-size';

interface Query {
  prompt: string;
  response: AiPromptResponse;
}

interface AiAgentState {
  queries: Query[];
  setQueries: (queries: Query[]) => void;
}

type AiAgentPersist = (
  config: StateCreator<AiAgentState>,
  options: PersistOptions<AiAgentState>,
) => StateCreator<AiAgentState>;

const useAiAgentStore = create<AiAgentState>(
  (persist as AiAgentPersist)(
    (set) => ({
      queries: [],
      setQueries: (queries: Query[]) => set({ queries }),
    }),
    {
      name: 'aiAgentStore',
    },
  ),
);

export { useAiAgentStore };

const getResponseFromPrompt = async (prompt: string): Promise<AiPromptResponse> => {
  const data: any = await graphQLClient.request(getPromptResponseQuery, {
    prompt,
  });

  return data.getPromptResponse;
};

type AiFormValues = {
  prompt: string;
};

type ContentTypesWithOmission = Exclude<
  ContentTypes,
  | ContentTypes.Poll
  | ContentTypes.MultiSessionWorkshopSession
  | ContentTypes.Presentation
  | ContentTypes.Quiz
>;

const contentTypeMap: Record<ContentTypesWithOmission, string> = {
  [ContentTypes.Article]: 'article',
  [ContentTypes.Assessment]: 'assessment',
  [ContentTypes.File]: 'file',
  [ContentTypes.Video]: 'video',
  [ContentTypes.ImportedContent]: 'imported-content',
  [ContentTypes.Journey]: 'journey',
  [ContentTypes.Scorm]: 'scorm',
  [ContentTypes.Event]: 'event',
  [ContentTypes.Media]: 'media',
  [ContentTypes.MultiSessionWorkshop]: 'multi-session-workshop',
  [ContentTypes.Track]: 'track',
  [ContentTypes.Workshop]: 'workshop',
};

export default function AIAgent() {
  useDocumentTitle('AI Agent');
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { feature_flags: featureFlags } = company;
  const { height: windowHeight } = useWindowSize();
  const { setToast } = useToastStore();
  const { queries, setQueries } = useAiAgentStore();
  const conversationContainerRef = useRef<HTMLDivElement>(null);

  const { backRoute, backLinkText } = location.state || {
    backRoute: '/explore',
    backLinkText: t('aiAgent.backToExplore'),
  };

  useEffect(() => {
    if (conversationContainerRef.current) {
      conversationContainerRef.current.scrollTo({
        top: conversationContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [queries]);

  const cardOptions = [
    {
      label: t('aiAgent.exampleOne'),
      icon: <Money />,
    },
    {
      label: t('aiAgent.exampleTwo'),
      icon: <Feedback />,
    },
    {
      label: t('aiAgent.exampleThree'),
      icon: <Conference />,
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isValid },
  } = useForm<AiFormValues>();

  const { prompt } = watch();

  const getResponseFromPromptMutation = useMutation({
    mutationFn: () => getResponseFromPrompt(prompt),
    onSuccess: (data) => {
      setQueries([...queries, { prompt, response: data }]);

      reset();
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('aiAgent.prompt.error'),
      }),
  });

  const { status } = getResponseFromPromptMutation;

  const onSubmit: SubmitHandler<AiFormValues> = () => getResponseFromPromptMutation.mutateAsync();

  if (!featureFlags.enable_ai_agent) {
    return <Navigate to="/explore" replace />;
  }

  return (
    <Box backgroundColor="neutral.50" height={windowHeight - 50}>
      <Flex justifyContent="flex-start" alignItems="center" padding={4} paddingLeft={10}>
        <Button
          variant="unstyled"
          display="flex"
          alignItems="center"
          fontSize="16px"
          fontWeight={500}
          leftIcon={<ArrowLeft />}
          onClick={() => navigate(backRoute)}
        >
          {backLinkText}
        </Button>
      </Flex>

      <Center height={windowHeight - 125}>
        <Container
          display="flex"
          flexDirection="column"
          paddingBottom={4}
          justifyContent={queries.length > 0 ? 'space-between' : 'flex-start'}
          maxWidth="3xl"
          minHeight="full"
        >
          <Flex direction="column" flex={queries.length > 0 ? 1 : 0}>
            <Stack spacing={4} borderBottom="1px solid" borderColor="neutral.200" paddingBottom={4}>
              <Stack spacing={0}>
                <Text fontSize="40px" fontWeight={600}>
                  {t('aiAgent.landing.title')}
                </Text>

                <Text fontSize="16px" fontWeight={400} color="neutral.1000">
                  {t('aiAgent.landing.helpText')}
                </Text>
              </Stack>
            </Stack>

            {!queries.length && (
              <HStack spacing={4} marginY={12}>
                {cardOptions.map((option) => (
                  <Box
                    key={option.label}
                    backgroundColor="transparent"
                    border="2px solid"
                    borderColor="neutral.200"
                    borderRadius="8px"
                    padding={4}
                  >
                    <Stack spacing={12}>
                      <Text fontSize="12px" fontWeight={600} color="neutral.1300">
                        {`"${option.label}"`}
                      </Text>

                      {option.icon}
                    </Stack>
                  </Box>
                ))}
              </HStack>
            )}

            {queries.length > 0 && (
              <Stack
                ref={conversationContainerRef}
                paddingTop={4}
                paddingX={4}
                width="full"
                flex="1 1 0"
                marginY={4}
                overflowY="auto"
                transition="all 0.3s ease-in-out"
                transform="translateY(0)"
                opacity={1}
                animation="slideIn 0.3s ease-in-out"
                sx={{
                  '@keyframes slideIn': {
                    '0%': {
                      transform: 'translateY(-20px)',
                      opacity: 0,
                    },
                    '100%': {
                      transform: 'translateY(0)',
                      opacity: 1,
                    },
                  },
                }}
              >
                {queries.map((query) => (
                  <Stack key={query.prompt} spacing={8} marginBottom={8}>
                    <Flex justifyContent="flex-end">
                      <Box padding={2.5} backgroundColor="brand.primary" borderRadius="12px">
                        <Text fontSize="14px" fontWeight={400} color="white">
                          {query.prompt}
                        </Text>
                      </Box>
                    </Flex>

                    <Flex justifyContent="flex-start">
                      <Box padding={4} backgroundColor="white" borderRadius="12px">
                        <Text fontSize="14px" fontWeight={400}>
                          {query.response.response}
                        </Text>

                        {query.response.references.length > 0 && (
                          <Stack marginTop={2} paddingLeft={4}>
                            {query.response.references.map((reference) => {
                              const { contentType } = reference;

                              if (
                                contentType === ContentTypes.Poll ||
                                contentType === ContentTypes.MultiSessionWorkshopSession ||
                                contentType === ContentTypes.Presentation ||
                                contentType === ContentTypes.Quiz
                              ) {
                                return null;
                              }

                              return (
                                <HStack key={reference.id} spacing={0}>
                                  <Icon as={PiDotOutlineFill} color="neutral.700" />

                                  <Link
                                    as={RouterLink}
                                    to={`/${
                                      contentTypeMap[contentType as ContentTypesWithOmission]
                                    }/${reference.id}`}
                                    fontSize="14px"
                                    fontWeight="semibold"
                                  >
                                    {reference.name}
                                  </Link>
                                </HStack>
                              );
                            })}
                          </Stack>
                        )}
                      </Box>
                    </Flex>
                  </Stack>
                ))}
              </Stack>
            )}
          </Flex>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box position="relative">
              <Textarea
                {...register('prompt', { required: true })}
                placeholder={`${t('aiAgent.prompt.placeholder')}`}
                resize="none"
                fontSize="14px"
                fontWeight={500}
                rows={5}
                backgroundColor="white"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                  }
                }}
                _placeholder={{ fontSize: '14px', fontWeight: 500, color: 'neutral.600' }}
              />

              <ButtonGroup zIndex={9999} position="absolute" right={4} bottom={4} size="xs">
                <Button
                  variant="createCancel"
                  isDisabled={!queries.length}
                  onClick={() => setQueries([])}
                >
                  {t('aiAgent.prompt.clearConversation')}
                </Button>

                <IconButton
                  type="submit"
                  isDisabled={!isValid}
                  aria-label="Ask AI"
                  width={20}
                  icon={<ArrowRight />}
                  isLoading={status === 'loading'}
                />
              </ButtonGroup>
            </Box>
          </form>
        </Container>
      </Center>
    </Box>
  );
}
