// IMPORTANT: Build gql after ANY changes to queries (client OR api)

import { graphql } from '@/client/services/api/graphql/gql';

export const getPromptResponseQuery: any = graphql(`
  query getPromptResponse($prompt: String!) {
    getPromptResponse(prompt: $prompt) {
      references {
        id
        contentType
        name
      }
      response
    }
  }
`);
