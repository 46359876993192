/* eslint-disable no-param-reassign */

import { Box, Text } from '@chakra-ui/react';
import { UseMutateAsyncFunction, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import CommentList from '../../components/lists/CommentList';
import type { CompletionMutationVariables } from '@/client/types/content/completion/ContentCompletion';
import ContentContainer from '../../components/layout/ContentContainer';
import ContentHeader from '../../components/layout/ContentHeader';
import ContentHero from '../../components/media/images/ContentHero';
import ContentRatingInput from '@/client/components/data-display/ratings/ContentRatingInput';
import ContentService from '@/client/services/api/ContentService';
import ContentStats from '../../components/data-display/content/ContentStats';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import HtmlRenderer from '../../components/html/HtmlRenderer';
import ImageModal from '@/client/components/overlay/ImageModal';
import { InView } from 'react-intersection-observer';
import Loading from '@/client/components/media/Loading';
import NotAuthorized from '@/client/components/data-display/content/NotAuthorized';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import Recommendations from '@/client/components/layout/Recommendations';
import SignatureRequiredBanner from '@/client/components/esignature/SignatureRequiredBanner';
import ViewActions from '@/client/components/overlay/ViewActions';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCheckContentAuth } from '@/client/services/hooks/content/useCheckContentAuth';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import { useSessionTracking } from '@/client/services/hooks/useSessionTracking';
import { useTranslation } from 'react-i18next';

// TODO: Look into refactoring content data to be fetched in ContentWrapper for all content types

interface ArticleProps {
  trackId?: string;
  journeyId?: string;
  contentId: string;
  needsContextSelection: boolean;
  sendCompleteContent: UseMutateAsyncFunction<any, unknown, CompletionMutationVariables, unknown>;
  contentSatisfied: boolean;
}

export default function Article({
  trackId = undefined,
  journeyId = undefined,
  contentId,
  needsContextSelection,
  sendCompleteContent,
  contentSatisfied,
}: ArticleProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { allowView } = useCheckContentAuth();
  const { partner } = usePartnerStore();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const [imageModalSource, setImageModalSource] = useState<string>('');
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [renderRecommendations, setRenderRecommendations] = useState<boolean>(false);
  const { sendSessionTime } = useSessionTracking(contentId, 'article');

  const allowRecommendations = !trackId && !journeyId;

  const { isLoading, isError, data } = useQuery({
    enabled: !!contentId,
    queryKey: ['article', contentId],
    queryFn: () => ContentService.getArticle(contentId),
  });

  const handleImageClick = (e: any) => {
    if (e.target.tagName === 'IMG') {
      setImageModalSource(e.target.src);
      setImageModalOpen(true);
    }
  };

  const contentRequiresSignature = useMemo(() => data && data.signature_required, [data]);

  const contentCompletionSatisfied = useMemo(
    () => typeof contentSatisfied !== 'undefined' && contentSatisfied,
    [contentSatisfied],
  );

  const renderSignatureBanner =
    company.feature_flags.enable_esignature &&
    contentRequiresSignature &&
    typeof contentSatisfied !== 'undefined' &&
    !contentCompletionSatisfied;

  const fireCompletionMutation =
    !contentRequiresSignature &&
    typeof contentSatisfied !== 'undefined' &&
    !contentCompletionSatisfied &&
    !needsContextSelection &&
    isAllowed;

  useEffect(() => {
    if (fireCompletionMutation) {
      sendCompleteContent({});
    }
  }, [fireCompletionMutation]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (!needsContextSelection && isAllowed) {
      AnalyzeService.postViews(contentId, 'article');
      ContentService.postViews(contentId, 'article');
    }

    return () => {
      sendSessionTime();
    };
  }, [needsContextSelection, isAllowed]);

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data);

        if (allowed) {
          checkShowPopupSurvey(data, true, !!trackId || !!journeyId);

          trackEvent('view', {
            content: data._id,
            content_type: 'article',
            title: data.title,
          });
        }

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  useDocumentTitle(data ? data.title : 'Article');

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title={t('modules.notifications.unableToGetArticle')} />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <Box position="relative" onClick={(e) => handleImageClick(e)}>
      {isAllowed && (
        <>
          {!trackId && !journeyId && (
            <ContentHeader
              contentType="article"
              contentTitle={data.title}
              contentId={contentId}
              withBorder
              allowComments={data.allow_comments}
            />
          )}

          <ContentContainer inJourney={!!journeyId}>
            <ContentStats
              author={data.author}
              duration={data.duration}
              ratingConfiguration={data.rating_configuration}
              averageRating={data.average_rating}
            />

            <Text variant="contentheader">{data.title}</Text>
          </ContentContainer>

          {data.banner_image && <ContentHero imageUrl={data.banner_image} />}

          <ContentContainer inArticle>
            <HtmlRenderer html={data.content} width="700px" />

            {allowRecommendations && (
              <InView
                as="div"
                onChange={(inView) => {
                  if (inView) {
                    setRenderRecommendations(true);
                  }
                }}
              />
            )}

            {renderRecommendations && <Recommendations contentId={contentId} />}

            {data.rating_configuration && data.rating_configuration.allow_rating && (
              <ContentRatingInput
                contentId={data._id}
                contentType="Article"
                ratingConfiguration={data.rating_configuration}
              />
            )}

            {data.allow_comments && !journeyId && !partner._id && (
              <CommentList contentId={contentId} contentType="article" contentTitle={data.title} />
            )}
          </ContentContainer>

          {!trackId && !journeyId && (
            <ViewActions
              buttons={['gototop']}
              bottomOffset={showPopup || renderSignatureBanner ? 14 : 0}
            />
          )}

          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.surveys[0]?.survey_id}
              contentId={contentId}
              contentType="article"
            />
          )}

          <ImageModal
            isOpen={imageModalOpen}
            imageSrc={imageModalSource}
            setIsOpen={() => setImageModalOpen(false)}
          />
        </>
      )}

      {renderSignatureBanner && (
        <SignatureRequiredBanner
          messaging={data.signature_agreement_text}
          onCompleteContent={sendCompleteContent}
        />
      )}
    </Box>
  );
}
