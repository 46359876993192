import { Box, Flex, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { Heart, HeartFilled } from '@/client/components/icons/ContinuIcons';
import { useMutation, useQuery } from '@tanstack/react-query';

import SuggestionsService from '@/client/services/api/SuggestionsService';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useAuthStore } from '@/client/services/state/authStore';

interface HeaderSuggestionsIconProps {
  contentType: string;
  contentId: string | undefined;
  contentTitle: string | undefined;
  lightIcons?: boolean;
}

export default function HeaderSuggestionsIcon({
  contentType,
  contentId,
  contentTitle,
  lightIcons = false,
}: HeaderSuggestionsIconProps) {
  const { authConfig } = useAuthStore();
  const { user } = authConfig;

  const { isLoading, isError, data, refetch } = useQuery(
    [`${contentType}-suggestions`, { contentType, contentId }],
    () => SuggestionsService.getSuggestions(contentType, contentId, user._id),
  );

  const addSuggestion = useMutation({
    mutationFn: () => SuggestionsService.addSuggestion(contentId, contentType, user._id),
    onSuccess: () => {
      trackEvent(contentType + '_liked', {
        content: contentId,
        content_type: contentType,
        title: contentTitle,
      });
      refetch();
    },
  });

  const removeSuggestion = useMutation({
    mutationFn: () =>
      SuggestionsService.removeSuggestion(data?.userSuggestionId, contentId, contentType),
    onSuccess: () => {
      trackEvent(contentType + '_unliked', {
        content: contentId,
        content_type: contentType,
        title: contentTitle,
      });
      refetch();
    },
  });

  if (isLoading || isError) return <Box />;

  return (
    // HIDES USER LIST TOOLTIP FOR NOW FOR EXTEND SECURITY PURPOSES
    // <Tooltip
    //   display={data.suggestionsUsers.length > 0 ? 'flex' : 'none'}
    //   hasArrow
    //   label={
    //     <Stack>
    //       {data.suggestionsUsers.map((value: string) => (
    //         <Text key={value}>{value}</Text>
    //       ))}
    //     </Stack>
    //   }
    // >
    <Flex alignItems="center">
      <IconButton
        isDisabled={addSuggestion.isLoading || removeSuggestion.isLoading || isLoading}
        aria-label="Like this content"
        variant="ghost"
        icon={
          data.userHasSuggested ? (
            <HeartFilled boxSize={6} color="brand.button" />
          ) : (
            <Heart
              boxSize={6}
              color={lightIcons ? 'white' : 'brand.iconGrey'}
              _hover={{ color: 'brand.button' }}
            />
          )
        }
        onClick={() => (data.userHasSuggested ? removeSuggestion.mutate() : addSuggestion.mutate())}
      />

      {data.count > 0 && <Text textColor={lightIcons ? 'white' : 'black'}>{data.count}</Text>}
    </Flex>
    // </Tooltip>
  );
}
