import type { Content, TrackExtras } from '@/client/services/api/graphql/gql/graphql';
import type {
  Course,
  LearningTrackSubmissionData,
} from '@/client/types/admin/content-authoring/courses/tracks/LearningTrackSubmissionData';

import AdminTracksService from '@/client/services/api/admin/content-authoring/AdminTracksService';
import AuthoringGraphqlService from '@/client/services/api/admin/content-authoring/AuthoringGraphqlService';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCheckContentEditable } from '../useCheckContentEditable';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';

export const useAuthorTrack = (trackId?: string | undefined) => {
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { checkContentEditable } = useCheckContentEditable();
  const { trackState, setTrackState } = useTrackStateStore();
  const { setTabIndex } = useCreateStore();
  const { watch, setValue } = useFormContext<Content & TrackExtras>();

  const getTrackForEditor = useMutation({
    mutationFn: () => AuthoringGraphqlService.getCourseForEditor(trackId, 'track', user._id),
    onSuccess: (data) => {
      if (!data) return;

      checkContentEditable(data);

      if (trackState && trackState.returningFromContent) {
        if (!trackState.contentTitle || !trackState.contentId || !data.sections) {
          return;
        }

        if (trackState.returningFromEditContent) {
          setTabIndex(1);

          setTrackState(null);

          return;
        }

        const targetSection = data.sections[trackState.section];

        targetSection.courses?.push({
          type: trackState.contentType,
          id: trackState.contentId,
          title: trackState.contentTitle,
          position: data.sections[trackState.section].courses?.length || 0,
        });

        setValue('sections', [
          ...data.sections.slice(0, trackState.section),
          targetSection,
          ...data.sections.slice(trackState.section + 1),
        ]);

        // TODO: Send track update call to API here?

        setTrackState(null);
        setTabIndex(1);
      }
    },
  });

  const trackPayload: Omit<
    LearningTrackSubmissionData,
    '_id' | 'draft' | 'author' | 'duration' | 'approval_required' | 'approved'
  > = {
    title: watch('title'),
    company: company._id,
    user: watch('creator') || user._id,
    name: watch('title'),
    banner_image: watch('bannerImage'),
    images: {
      explore: watch('image'),
    },
    creator_name: '...',
    creator_bio: '...',
    explore_hide: !watch('exploreHide'),
    description: watch('description'),
    private: watch('private'),
    allow_comments: watch('allowComments'),
    linked_category: watch('linkedCategory')?.map((category) => category.id),
    privacy_collaborators: watch('privacyCollaborators')?.map((collaborator) => collaborator.id),
    privacy_locations: watch('privacyLocations')?.map((location) => location.id),
    privacy_departments: watch('privacyDepartments')?.map((department) => department.id),
    privacy_teams: watch('privacyTeams')?.map((team) => team.id),
    privacy_org_levels: watch('privacyOrgLevels')?.map((orgLevel) => orgLevel.id),
    privacy_groups: watch('privacyGroups')?.map((group) => group.id),
    privacy_grades: watch('privacyGrades')?.map((grade) => grade.id),
    rating_configuration: {
      allow_edits: watch('ratingConfiguration.allowEdits'),
      allow_feedback: watch('ratingConfiguration.allowFeedback'),
      allow_rating: watch('ratingConfiguration.allowRating'),
      messaging: {
        individuals: watch('ratingConfiguration.messaging.individuals').map(
          (individual) => individual.id,
        ),
        slack_channels: watch('ratingConfiguration.messaging.slackChannels').map(
          (channel) => channel.id,
        ),
      },
      notify_feedback_only: watch('ratingConfiguration.notifyFeedbackOnly'),
      show_users_rating: watch('ratingConfiguration.showUsersRating'),
      total_value: watch('ratingConfiguration.totalValue'),
    },
    surveys: watch('surveys')?.map((survey) => ({ survey_id: survey.id })),
    // TODO: Fix this circular type dependency
    // @ts-ignore
    tags: watch('tags'),
    partner_permissions: watch('partnerPermissions'),
    resource: watch('resource'),
    sequential: watch('sequential'),
    lock_scorm: false, // TODO: Remove this once verified it causes no issues
    learn_items: watch('learnItems'),
    sections: watch('sections')?.map((section, sectionIndex) => ({
      name: section.name,
      description: section.description,
      position: sectionIndex + 1,
      courses: section.courses?.map((course, courseIndex) => {
        const courseObj: Course = {
          // ! Type conflict here between graphQL returning id and REST API returning _id
          // @ts-ignore
          contentId: course.id || course._id,
          position: courseIndex + 1,
          contentTitle: course.title,
        };

        // ! Type conflict here between graphQL returning id and REST API returning _id
        // @ts-ignore
        courseObj[course.type] = course.id || course._id;

        return courseObj;
      }),
    })),
    certificate: watch('certificate'),
    certificate_data: watch('certificateData'),
    allCourseIds:
      watch('sections')?.map(
        (section) =>
          // @ts-ignore
          section?.courses?.map((course) => course.id || course._id)[0],
      ) || [],
    instructor_ids: watch('instructors')?.map((instructor) => instructor.id) || [],
  };

  type SubmitTrackMutationVariables = Pick<
    LearningTrackSubmissionData,
    'approval_required' | 'approved' | 'draft'
  >;

  const submitTrackMutation = useMutation({
    mutationFn: (variables: SubmitTrackMutationVariables) =>
      AdminTracksService.saveTrack({ ...trackPayload, ...variables }, watch('id')),
    onSuccess: (data) => {
      // TODO: Analyze calls

      setValue('id', data._id);
    },
  });

  return {
    getTrackForEditor,
    submitTrackMutation,
    trackPayload,
  };
};
