import { Navigate, Outlet } from 'react-router-dom';

import { useAuthStore } from '@/client/services/state/authStore';

export default function AutoAttendWorkshopWrapper() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  if (!company.feature_flags.enable_auto_workshop_attendance)
    return <Navigate to="/explore" replace />;

  return <Outlet />;
}
