/* eslint-disable react/jsx-props-no-spreading */

import * as z from 'zod';

import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useEffect, useRef } from 'react';

import AvatarUploadForm from '@/client/components/shared/forms/AvatarUploadForm';
import ContentFormTextGroup from '../../text/ContentFormTextGroup';
import ContentInputGroup from '../../../../input/ContentInputGroup';
import CreateSwitchContainer from '../../../shared/layout/CreateSwitchContainer';
import type { Instructor } from '@/client/services/api/graphql/gql/graphql';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import TinyMceEditor from '../../../shared/TinyMceEditor';
import { isValidAttribute } from 'dompurify';
import { useAvatarStore } from '@/client/services/state/shared/avatarStore';
import { useEditInstructors } from '@/client/services/hooks/admin/authoring/courses/useEditInstructors';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { useUploadImage } from '@/client/services/hooks/shared/images/useUploadImage';
import { zodResolver } from '@hookform/resolvers/zod';

const instructorSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  socials: z.object({
    linkedin: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/, {
        message: 'Invalid LinkedIn Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
    twitter: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?x\.com\//, {
        message: 'Invalid Twitter Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
    facebook: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?facebook\.com\//, {
        message: 'Invalid Facebook Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
    slack: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?slack\.com\/team/, {
        message: 'Invalid Slack Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
    github: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?github\.com\//, {
        message: 'Invalid GitHub Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
    instagram: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?instagram\.com\//, {
        message: 'Invalid Instagram Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
    youtube: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?youtube\.com\//, {
        message: 'Invalid Youtube Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
    dribbble: z
      .string()
      .regex(/^(http(s)?:\/\/)?([\w]+\.)?dribbble\.com\//, {
        message: 'Invalid Dribbble Profile URL',
      })
      .optional()
      .or(z.literal(''))
      .or(z.null()),
  }),
});

interface InstructorEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  instructorData?: Omit<Instructor, 'archived'> | null; // TODO: Type this properly from getOne
}

export default function InstructorEditModal({
  isOpen,
  onClose,
  instructorData = null,
}: InstructorEditModalProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { watch: parentFormWatch, setValue: parentFormSetValue } = useFormContext();
  const avatarRef = useRef<HTMLInputElement>(null);
  const { avatar, setAvatar, reset: resetAvatar } = useAvatarStore();
  const { uploadImageMutation } = useUploadImage();
  const { status } = uploadImageMutation;
  const { createInstructorMutation, updateInstructorMutation } = useEditInstructors();

  const currentInstructors = parentFormWatch('instructors');

  const instructorDefaultValues: Omit<Instructor, 'id' | 'archived' | 'createdAt'> = {
    name: '',
    email: '',
    image: '',
    bio: '',
    notes: '',
    reusable: false,
    socials: {
      linkedin: '',
      twitter: '',
      facebook: '',
      slack: '',
      github: '',
      instagram: '',
      youtube: '',
      dribbble: '',
    },
  };

  const socialValueMap = [
    {
      name: 'linkedin',
      label: t('trackviewer.social.linkedin'),
      placeholder: 'www.linkedin.com/in/profile',
    },
    {
      name: 'twitter',
      label: t('trackviewer.social.twitter'),
      placeholder: t('authoring.courses.instructors.socialPlaceholder'),
    },
    {
      name: 'facebook',
      label: t('trackviewer.social.facebook'),
      placeholder: t('authoring.courses.instructors.socialPlaceholder'),
    },
    {
      name: 'slack',
      label: t('trackviewer.social.slack'),
      placeholder: t('authoring.courses.instructors.socialPlaceholder'),
    },
    {
      name: 'github',
      label: t('trackviewer.social.github'),
      placeholder: t('authoring.courses.instructors.socialPlaceholder'),
    },
    {
      name: 'instagram',
      label: t('trackviewer.social.instagram'),
      placeholder: t('authoring.courses.instructors.socialPlaceholder'),
    },
    {
      name: 'youtube',
      label: t('trackviewer.social.youtube'),
      placeholder: t('authoring.courses.instructors.socialPlaceholder'),
    },
    {
      name: 'dribbble',
      label: t('trackviewer.social.dribbble'),
      placeholder: t('authoring.courses.instructors.socialPlaceholder'),
    },
  ];

  const methods = useForm({
    resolver: zodResolver(instructorSchema),
    defaultValues: instructorDefaultValues,
    mode: 'onChange',
  });

  const { formState, watch, register, getValues, reset, setValue, trigger } = methods;

  const { isValid, isDirty, errors } = formState;

  const { name, email, bio, notes, image, reusable, socials } = watch();

  const handleClose = () => {
    reset();

    onClose();
  };

  const { status: createInstructorStatus } = createInstructorMutation;

  const onSubmit = () => {
    if (instructorData) {
      const instructorDataPayload = {
        name,
        email,
        bio,
        notes,
        image,
        reusable,
        socials,
      };

      updateInstructorMutation
        .mutateAsync({
          id: instructorData.id,
          payload: instructorDataPayload,
        })
        .then((successData) => {
          parentFormSetValue(
            'instructors',
            currentInstructors.map((instructor: any) =>
              instructor.id === instructorData?.id ? successData.updateInstructor : instructor,
            ),
          );

          setToast({
            show: true,
            status: 'success',
            title: 'Instructor Successfully Updated',
          });

          handleClose();
        });

      return;
    }

    createInstructorMutation.mutateAsync(getValues()).then((successData) => {
      parentFormSetValue('instructors', [
        ...currentInstructors,
        { id: successData, ...getValues() },
      ]);

      setToast({
        show: true,
        status: 'success',
        title: 'Instructor Successfully Created',
      });

      handleClose();
    });
  };

  useEffect(() => {
    if (instructorData) {
      reset(instructorData);

      return;
    }

    reset(instructorDefaultValues);
  }, [instructorData]);

  // TODO: Refactor into useUploadImage hook for use in other components
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({
        show: true,
        status: 'error',
        title: t('global.imageUploadError'),
      });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    setAvatar({
      ...avatar,
      img: fileObject,
      editorModalOpen: true,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="3xl"
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text variant="createHeading">
            {instructorData
              ? t('authoring.courses.instructors.modal.edit')
              : t('authoring.courses.instructors.modal.addNew')}
          </Text>
        </ModalHeader>

        <FormProvider {...methods}>
          <ModalBody>
            <Stack>
              <ContentInputGroup
                label={t('authoring.courses.instructors.modal.fullName')}
                placeholder={t('authoring.courses.instructors.modal.fullName.placeholder')}
                formName="name"
                isRequired
              />

              <Divider borderColor="neutral.100" />

              <ContentInputGroup
                label={t('authoring.courses.instructors.modal.email')}
                placeholder={t('authoring.courses.instructors.modal.email.placeholder')}
                formName="email"
              />

              <Divider borderColor="neutral.100" />

              <Flex justifyContent="space-between" paddingY={8}>
                <Box maxWidth="35%">
                  <ContentFormTextGroup
                    label={t('authoring.courses.instructors.modal.image')}
                    helpText={t('authoring.courses.instructors.modal.image.helpText')}
                  />
                </Box>

                <AvatarUploadForm
                  isLoading={status === 'loading'}
                  handleUpload={(file) =>
                    uploadImageMutation.mutate({
                      file,
                      errorMessage: t('global.avatarUploadError'),
                      successActions: (cloudfrontLink) => {
                        setValue('image', cloudfrontLink, {
                          shouldDirty: true,
                        });
                        resetAvatar();
                      },
                    })
                  }
                  uploadElement={
                    <HStack spacing={6}>
                      <Avatar name={name} src={image || undefined} />

                      <Stack>
                        <SolidButton
                          onClick={() => {
                            avatarRef.current!.click();
                          }}
                        >
                          {t('authoring.courses.instructors.modal.image.upload')}
                        </SolidButton>

                        <Input
                          type="file"
                          display="none"
                          ref={avatarRef}
                          accept="image/*"
                          onChange={(e) => handleFileChange(e)}
                        />

                        {image && image !== '' && (
                          <Button
                            variant="ghost"
                            border="2px solid"
                            borderColor="red"
                            borderRadius="8px"
                            color="red"
                            paddingY={3}
                            paddingX={8}
                            onClick={() => {
                              setValue('image', '', { shouldDirty: true });
                              resetAvatar();
                            }}
                          >
                            {t('authoring.courses.instructors.modal.image.remove')}
                          </Button>
                        )}
                      </Stack>
                    </HStack>
                  }
                />
              </Flex>

              <Divider borderColor="neutral.100" />

              <Stack spacing={6} marginY={8}>
                <ContentFormTextGroup
                  label={t('authoring.courses.instructors.modal.bio')}
                  helpText={t('authoring.courses.instructors.modal.bio.helpText')}
                />
                <TinyMceEditor
                  isRequired
                  formName="bio"
                  placeholder={`${t('authoring.courses.instructors.modal.bio.placeholder')}`}
                  label=""
                  useMenu={false}
                  toolbarOpts="bold italic underline fontsize emoticons | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | link removeformat | undo redo"
                />
              </Stack>

              <Divider borderColor="neutral.100" />

              <Box marginY={8}>
                <CreateSwitchContainer
                  name="reusable"
                  label={t('authoring.courses.instructors.reusable.label')}
                  helpText={t('authoring.courses.instructors.reusable.helpText')}
                />
              </Box>

              <Divider borderColor="neutral.100" />

              <Stack spacing={6} marginY={8}>
                <ContentFormTextGroup
                  label={t('authoring.courses.instructors.modal.notes')}
                  helpText={t('authoring.courses.instructors.modal.notes.helpText')}
                />
                <TinyMceEditor
                  isRequired
                  formName="notes"
                  placeholder={`${t('authoring.courses.instructors.modal.notes.placeholder')}`}
                  label=""
                  useMenu={false}
                  toolbarOpts="bold italic underline fontsize emoticons | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | link removeformat | undo redo"
                />
              </Stack>

              <Divider borderColor="neutral.100" />

              <Grid marginY={8} templateColumns="repeat(6, 1fr)" gap={6}>
                <GridItem colSpan={2}>
                  <ContentFormTextGroup
                    label={t('authoring.courses.instructors.modal.socialMedia')}
                  />
                </GridItem>

                <GridItem colSpan={4}>
                  <Stack spacing={6}>
                    {socialValueMap.map((social) => {
                      const { name: socialName, label, placeholder } = social;

                      // @ts-ignore
                      const hasError = !!errors?.socials?.[socialName];

                      return (
                        <FormControl key={label} isInvalid={hasError}>
                          <Stack key={socialName} spacing={1}>
                            <HStack>
                              <Text fontSize="14px" fontWeight={600}>
                                {label}
                              </Text>

                              {hasError && (
                                <Text fontSize="14px" color="red.500">
                                  {/* @ts-ignore */}
                                  {errors.socials?.[socialName].message}
                                </Text>
                              )}
                            </HStack>

                            <Input
                              variant="create"
                              placeholder={placeholder}
                              {...register(`socials.${socialName}` as any)}
                            />
                          </Stack>
                        </FormControl>
                      );
                    })}
                  </Stack>
                </GridItem>
              </Grid>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup justifyContent="flex-end">
              <Button variant="createCancel" onClick={onClose}>
                {t('authoring.courses.instructors.modal.cancel')}
              </Button>

              <SolidButton
                type="submit"
                isLoading={createInstructorStatus === 'loading'}
                isDisabled={!isDirty || !isValid}
                onClick={onSubmit}
                onMouseOver={() => trigger()}
              >
                {instructorData ? 'Update' : t('authoring.courses.instructors.modal.save')}
              </SolidButton>
            </ButtonGroup>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
