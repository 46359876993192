import CategoriesService from '@/client/services/api/CategoriesService';
import GroupsService from '@/client/services/api/admin/connect/GroupsService';
import { useAuthStore } from '@/client/services/state/authStore';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

export const useAuthoringSegmentationSearch = () => {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const groupsEnabled = company.groups;

  const search = async (query: string | undefined) => {
    if (!query) return;

    const promises: any = [CategoriesService.searchCategories(query)];

    if (groupsEnabled) {
      promises.push(GroupsService.simpleSearch(query, { pageIndex: 1, pageSize: 50 }));
    }

    const [categories, groups] = await Promise.all(promises);

    // ! Convert _id to id for use with GraphQL
    const convertedCategories = categories.map((section: any) => ({
      ...section,
      options: section.options.map((option: any) => ({
        ...option,
        id: option._id,
      })),
    }));

    const convertedGroups =
      groups?.map((group: any) => ({
        ...group,
        id: group._id,
      })) || [];

    if (groupsEnabled && groups) {
      return [convertedCategories, convertedGroups];
    }

    return [convertedCategories];
  };

  const { isLoading, isFetching, isError, data } = useQuery({
    enabled: !!searchTerm,
    queryKey: ['authoringSegmentationSearch', searchTerm],
    queryFn: () => search(searchTerm),
    select: (selectData) => {
      if (!selectData) return [];

      const filteredCategories = selectData[0].filter(
        (category: any) => category.options.length > 0,
      );

      if (selectData[1] && selectData[1].length > 0) {
        return [
          ...filteredCategories,
          {
            title: 'Groups',
            options: selectData[1].map((group: any) => ({
              ...group,
              type: 'group',
            })),
          },
        ];
      }

      return [...filteredCategories];
    },
  });

  return {
    searchTerm,
    setSearchTerm,
    isLoading,
    isFetching,
    isError,
    data,
  };
};
