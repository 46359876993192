import { v3ApiClient } from '@/client/services/api/clients/v3ApiClient';

const checkAiEnabled = async (): Promise<boolean> => {
  const response = await v3ApiClient.get('companyintegrations');

  const companyIntegration = response.data.length > 0 ? response.data[0] : null;
  let aiEnabled = false;

  if (
    companyIntegration &&
    companyIntegration.continu_insights &&
    companyIntegration.continu_insights.privileges &&
    companyIntegration.continu_insights.privileges.enableAI
  ) {
    aiEnabled = true;
  }

  return aiEnabled;
};

const ContinuInsightsService = {
  checkAiEnabled,
};

export default ContinuInsightsService;
