// IMPORTANT: Build gql after ANY changes to queries (client OR api)
import { graphql } from "@/client/services/api/graphql/gql/gql";

export const createAgreementText: any = graphql(`
  mutation CreateAgreementText($text: String!, $companyDefault: Boolean!) {
    createAgreementText(
      create: { 
        text: $text, 
        companyDefault: $companyDefault 
      }
    ) {
      id
    }
  }
`);