import type {
  FileDraftData,
  FileSubmissionData,
} from "@/client/types/admin/content-authoring/files/FileSubmissionData";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import AuthoringFileService from "@/client/services/api/admin/content-authoring/AuthoringFileService";
import AuthoringGraphqlService from "@/client/services/api/admin/content-authoring/AuthoringGraphqlService";
import { FileFormData } from "@/client/routes/admin/create/content/EditFile";
import GenerativeService from "@/client/services/api/graphql/GenerativeService";
import { TextGenerationStatus } from "@/client/services/api/graphql/gql/graphql";
import { useAuthStore } from "@/client/services/state/authStore";
import { useFormContext } from "react-hook-form";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";

export const useAuthorFile = (fileId?: string | undefined) => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { watch, setValue } = useFormContext<FileFormData>();
  const [uploadProgress, setUploadProgress] = useState(0);

  const id = watch("id");
  const isTextGenerating = watch("textGenerationStatus");
  const sourceIdV2 = watch("sourceIdV2");

  const getFileForEditor = useQuery({
    enabled: !!fileId,
    queryKey: ["file-for-editor", fileId, user._id],
    queryFn: () => AuthoringGraphqlService.getFileForEditor(fileId, user._id),
  });

  const acceptedFileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/excel",
    "application/vnd.ms-excel",
    "application/vnd.msexcel",
    "text/csv",
    "application/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".xls",
    ".xlsx",
    ".txt",
    ".doc",
    ".docx",
  ];

  const destroyCheck: { [key: string]: boolean } = {};

  const submitPayload: Omit<
    FileSubmissionData,
    "approval_required" | "approved" | "draft"
  > = {
    fileName: watch("fileDetails")?.name,
    allow_comments: watch("allowComments"),
    archived: watch("archived"),
    author: watch("author")!,
    company: company._id,
    user: watch("creator"),
    description: watch("description"),
    downloadable: watch("downloadable"),
    duration: watch("duration"),
    embed: watch("embed"),
    explore_hide: !watch("exploreHide"),
    images: {
      explore: watch("image"),
    },
    link: watch("link"),
    linked_category: watch("linkedCategory")?.map((category) => category.id),
    privacy_collaborators: watch("privacyCollaborators")?.map(
      (collaborator) => collaborator.id
    ),
    privacy_locations: watch("privacyLocations")?.map(
      (location) => location.id
    ),
    privacy_departments: watch("privacyDepartments")?.map(
      (department) => department.id
    ),
    privacy_teams: watch("privacyTeams")?.map((team) => team.id),
    privacy_org_levels: watch("privacyOrgLevels")?.map(
      (orgLevel) => orgLevel.id
    ),
    privacy_groups: watch("privacyGroups")?.map((group) => group.id),
    privacy_grades: watch("privacyGrades")?.map((grade) => grade.id),
    private: watch("private"),
    rating_configuration: {
      allow_edits: watch("ratingConfiguration.allowEdits"),
      allow_feedback: watch("ratingConfiguration.allowFeedback"),
      allow_rating: watch("ratingConfiguration.allowRating"),
      messaging: {
        individuals: watch("ratingConfiguration.messaging.individuals")?.map(
          (individual) => individual.id
        ),
        slack_channels: watch(
          "ratingConfiguration.messaging.slackChannels"
        )?.map((channel) => channel.id),
      },
      notify_feedback_only: watch("ratingConfiguration.notifyFeedbackOnly"),
      show_users_rating: watch("ratingConfiguration.showUsersRating"),
      total_value: watch("ratingConfiguration.totalValue"),
    },
    source: watch("source"),
    source_id: watch("sourceId"),
    source_id_v2: watch("sourceIdV2"),
    surveys: watch("surveys")?.map((survey) => ({ survey_id: survey.id })),
    // TODO: Fix this circular type dependency
    // @ts-ignore
    tags: watch("tags"),
    title: watch("title"),
    _id: watch("id"),
    type: "file",
    partner_permissions: watch("partnerPermissions"),
    signature_required: watch('signatureRequired'),
    signature_agreement_text: watch('signatureAgreementText')?.id,
  };

  const fileDetails = watch("fileDetails");

  const draftPayload: FileDraftData = {
    allow_comments: watch("allowComments"),
    archived: watch("archived"),
    draft: watch("draft"),
    embed: watch("embed"),
    link: watch("link"),
    explore_hide: watch("exploreHide"),
    fileName: fileDetails?.name,
    images: {}, // TODO: Images for files are not being handled properly by getOne
    linked_category: watch("linkedCategory")?.map((category) => category.id),
    privacy_collaborators: watch("privacyCollaborators")?.map(
      (collaborator) => collaborator.id
    ),
    tags: watch("tags"),
    title: watch("title") || "Draft, needs title",
    type: "file",
    user: user._id,
  };

  type SubmitFileMutationVariables = Pick<
    FileSubmissionData,
    "approval_required" | "approved" | "draft"
  >;

  const submitFileMutation = useMutation({
    mutationFn: (variables: SubmitFileMutationVariables) =>
      AuthoringFileService.submitFile({ ...submitPayload, ...variables }),
    onError: (error) => {
      console.error("Error creating file", error);

      setToast({ show: true, status: "error", title: "Error Creating File" });
    },
  });

  const checkIsPdf = (link: string) =>
    link &&
    typeof link.slice(-3) === "string" &&
    link.slice(-3).toLowerCase() === "pdf";

  const saveInitialDraft = useMutation({
    mutationFn: (payload: FileDraftData) =>
      AuthoringFileService.saveInitialDraft(payload),
    onSuccess: (data) => {
      const { link: cloudFrontLink } = data;

      setValue("creator", data.user);

      if (checkIsPdf(cloudFrontLink)) {
        setValue("link", data.link);
        setValue("id", data._id);

        return;
      }

      setValue("link", data.link);
      setValue("id", data._id);
    },
  });

  const boxSourceMutation = useMutation({
    mutationFn: (link: string) => AuthoringFileService.boxSource(link),
    onSuccess: (data, link) => {
      if (!data || !data.source_id_v2) return;

      destroyCheck[data.source_id_v2] = true;

      setValue("sourceIdV2", data.source_id_v2);
      setValue("sourceId", data.source_id);
      setValue("sourceV2", data.source_v2);
      setValue("source", data.source);
      setValue("link", link);
    },
  });

  const uploadFileMutation = useMutation({
    mutationFn: (file: File) =>
      AuthoringFileService.uploadFile(file, setUploadProgress),
    onSuccess: async (data, file) => {
      const { cloudFrontLink } = data;

      if (checkIsPdf(cloudFrontLink)) {
        if (id) {
          setValue("link", cloudFrontLink);

          return;
        }

        await saveInitialDraft.mutateAsync({
          ...draftPayload,
          link: cloudFrontLink,
        });
      } else {
        await boxSourceMutation.mutateAsync(cloudFrontLink).then((boxData) => {
          if (id) {
            setValue("link", cloudFrontLink);

            return;
          }

          saveInitialDraft.mutateAsync({
            ...draftPayload,
            ...boxData,
            link: cloudFrontLink,
          });
        });
      }

      setValue("fileDetails", {
        name: file.name,
        size: file.size,
      });

      setToast({
        show: true,
        status: "success",
        title: t("authoring.file.uploadSuccess"),
      });
    },
    onError: (error) => {
      console.error(error);

      setValue("fileDetails", null);

      setToast({
        show: true,
        status: "error",
        title: t("authoring.file.uploadError"),
      });
    },
  });

  const { data: textGenerationStatus } = useQuery({
    enabled:
      !!id &&
      !sourceIdV2 &&
      (isTextGenerating === TextGenerationStatus.InProgress ||
        isTextGenerating === undefined),
    queryKey: ["text_generation_status", id, user._id],
    queryFn: () =>
      GenerativeService.getTextGenerationStatus(id, "file", user._id),
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (textGenerationStatus === TextGenerationStatus.InProgress) {
      return;
    }
    if (textGenerationStatus === TextGenerationStatus.Completed) {
      setValue("textGenerationStatus", TextGenerationStatus.Completed);
    }
    if (textGenerationStatus === TextGenerationStatus.Failed) {
      setValue("textGenerationStatus", TextGenerationStatus.Failed);
    }
  }, [textGenerationStatus]);

  return {
    getFileForEditor,
    uploadProgress,
    acceptedFileTypes,
    submitFileMutation,
    uploadFileMutation,
    textGenerationStatus,
    saveInitialDraft,
    draftPayload,
  };
};
