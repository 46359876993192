import { Button, Flex, Text } from '@chakra-ui/react';

import { CompletionMutationVariables } from '@/client/types/content/completion/ContentCompletion';
import type { ESignatureAgreementText } from '@/client/types/esignature/ESignatureAgreementText';
import { FaPenAlt } from 'react-icons/fa';
import SignatureModal from './SignatureModal';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SignatureRequiredBannerProps {
  messaging: ESignatureAgreementText;
  onCompleteContent: UseMutateAsyncFunction<any, unknown, CompletionMutationVariables, unknown>;
}

export default function SignatureRequiredBanner({
  messaging,
  onCompleteContent,
}: SignatureRequiredBannerProps) {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Flex
        width="full"
        backgroundColor="brand.primary"
        paddingY={3}
        paddingX={6}
        justifyContent="space-between"
        alignItems="center"
        position="fixed"
        bottom={0}
        left={50}
        zIndex={9999}
      >
        <Text fontWeight={600} color="white">
          {t('esignature.banner.required')}
        </Text>

        <Button
          marginRight={12}
          backgroundColor="white"
          color="brand.primary"
          leftIcon={<FaPenAlt />}
          _hover={{
            backgroundColor: 'white',
          }}
          onClick={() => setModalIsOpen(true)}
        >
          {t('esignature.banner.signNow')}
        </Button>
      </Flex>

      <SignatureModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        messaging={messaging}
        onCompleteContent={onCompleteContent}
      />
    </>
  );
}
