import { AbsoluteCenter, Box, Button, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { differenceInMinutes, differenceInSeconds, format, intervalToDuration } from 'date-fns';
import { useInterval, useTimeout } from 'usehooks-ts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { urlProtocol } from '@/client/utils/urlProtocol';

import type { UserWorkshop } from '@/client/types/content/workshops/UserWorkshop';
import WorkshopService from '@/client/services/api/content/workshops/WorkshopService';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';
import { useAuthStore } from '@/client/services/state/authStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface InstanceProps {
  instance: {
    workshopName: string;
    workshopId: string;
    instanceStartDate: string;
    instanceEndDate: string;
    onlineLocation: string;
    userWorkshops: UserWorkshop[];
  };
}

interface WithMutationProps {
  mutate: () => void;
  instance: InstanceProps['instance'];
}

function WorkshopRedirect({ instance, mutate }: WithMutationProps) {
  const { t } = useTranslation();
  const { workshopName } = instance;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useTimeout(() => buttonRef.current?.click(), 1000);

  return (
    <VStack spacing={6}>
      <Spinner size="lg" color="neutral.300" />

      <Text fontSize="18px" fontWeight={500}>
        {t('autoAttendWorkshop.workshopRedirect.takingYouTo')}
        <Text as="span" fontWeight={600}>
          {workshopName}
        </Text>
      </Text>

      <Button ref={buttonRef} onClick={() => mutate()} hidden />
    </VStack>
  );
}

function WorkshopStartsMoreThanFive({ instance }: InstanceProps) {
  const { t } = useTranslation();
  const { workshopId, instanceStartDate } = instance;

  return (
    <VStack>
      <Text fontSize="18px" fontWeight={500}>
        {t('autoAttendWorkshop.moreThanFive.title')}
      </Text>

      <Text fontSize="18px" fontWeight={500}>
        {t('autoAttendWorkshop.moreThanFive.helpText')}{' '}
        {format(new Date(instanceStartDate), 'MMMM do yyyy, h:mm a')}
      </Text>

      <Text>
        <Link as={RouterLink} to={`/workshop/${workshopId}`} replace>
          {t('autoAttendWorkshop.moreThanFive.visitWorkshop')}
        </Link>{' '}
        {t('autoAttendWorkshop.moreThanFive.or')}{' '}
        <Link as={RouterLink} to="/explore" replace>
          {t('autoAttendWorkshop.moreThanFive.returnToExplore')}
        </Link>
      </Text>
    </VStack>
  );
}

function WorkshopStartsLessThanFive({ instance, mutate }: WithMutationProps) {
  const { t } = useTranslation();
  const { workshopName, instanceStartDate } = instance;
  const [countdownTime, setCountdownTime] = useState<number | null>(null);

  useInterval(
    () => {
      const diffInSeconds = differenceInSeconds(new Date(instanceStartDate), new Date());

      setCountdownTime(diffInSeconds);

      if (diffInSeconds <= 0) {
        mutate();
      }
    },

    1000,
  );

  const duration = intervalToDuration({ start: 0, end: countdownTime! * 1000 });

  return (
    <VStack>
      <Text fontSize="18px" fontWeight={500}>
        {t('autoAttendWorkshop.lessThanFive.label')}
      </Text>

      <Text fontSize="18px" fontWeight={500}>
        {t('autoAttendWorkshop.lessThanFive.helpText')}
      </Text>

      {countdownTime && (
        <Text fontSize="18px" fontWeight={500}>
          {`${t('autoAttendWorkshop.lessThanFive.redirectingIn')} ${duration.minutes}m ${
            duration.seconds
          }s`}
        </Text>
      )}

      <Box
        backgroundColor="neutral.0"
        border="1px solid"
        borderColor="neutral.100"
        borderRadius="8px"
        padding={4}
        marginTop={4}
      >
        <VStack fontSize="18px" fontWeight={600}>
          <Text>{workshopName}</Text>

          <Text>{format(new Date(instanceStartDate), 'MMMM do yyyy, h:mm a')}</Text>
        </VStack>
      </Box>
    </VStack>
  );
}

function WorkshopOver({ instance }: InstanceProps) {
  const { t } = useTranslation();
  const { workshopName, instanceStartDate } = instance;

  return (
    <Box paddingX={16}>
      <Text textAlign="center" fontSize="18px" fontWeight={500}>
        {t('autoAttendWorkshop.workshopOver.whoops')}{' '}
        <Text as="span" fontWeight={600}>
          {workshopName}
        </Text>
        {t('autoAttendWorkshop.workshopOver.alreadyOccurred')}{' '}
        {format(new Date(instanceStartDate), 'MMMM do yyyy, h:mm a')}.
      </Text>
    </Box>
  );
}

function NotRegistered({ instance }: InstanceProps) {
  const { t } = useTranslation();
  const { workshopId } = instance;

  return (
    <VStack>
      <Text textAlign="center" fontSize="18px" fontWeight={500}>
        {t('autoAttendWorkshop.notRegistered.label')}
      </Text>

      <Text>
        <Link as={RouterLink} to={`/workshop/${workshopId}`} replace>
          {t('autoAttendWorkshop.moreThanFive.visitWorkshop')}
        </Link>{' '}
        {t('autoAttendWorkshop.moreThanFive.or')}{' '}
        <Link as={RouterLink} to="/explore" replace>
          {t('autoAttendWorkshop.moreThanFive.returnToExplore')}
        </Link>
      </Text>
    </VStack>
  );
}

export default function AutoAttendWorkshop() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setToast } = useToastStore();

  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { _id: userId } = user;

  const getInstanceDetails = async (instanceId: string) => {
    const response = await learnApiClient.post(`/workshop-instances/attend/${instanceId}`);

    return response.data;
  };

  interface MarkAttendanceMutationParams {
    onlineLocation: string;
    instanceId: string;
  }

  const { mutateAsync: registerForWorkshopAsync } = useMutation({
    mutationFn: (params: MarkAttendanceMutationParams) => getInstanceDetails(params.instanceId),
    onSuccess: (params) => {
      window.location.replace(urlProtocol(params.onlineLocation));
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('autoAttendWorkshop.markAttendance.error'),
      });
    },
  });

  const {
    data: instanceData,
    isLoading: instanceIsLoading,
    isError: instanceIsError,
  } = useQuery({
    enabled: !!id,
    queryKey: ['workshop-instance', userId, id],
    queryFn: () => WorkshopService.getInstanceDetailsByInstanceId(userId, id),
  });

  if (instanceIsLoading) {
    return (
      <Box height="100vh">
        <AbsoluteCenter>
          <Spinner size="lg" color="neutral.300" />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (instanceIsError) {
    return (
      <Box height="100vh">
        <AbsoluteCenter>
          <Text fontSize="18px" fontWeight={500}>
            {t('autoAttendWorkshop.fetchDetails.error')}
          </Text>
        </AbsoluteCenter>
      </Box>
    );
  }

  const userIsRegisteredForThisInstance =
    instanceData.userWorkshops.filter(
      (userWorkshop) =>
        userWorkshop.workshop_instance === id && userWorkshop.active_status === 'registered',
    ).length > 0;

  const diffInMinutes = differenceInMinutes(new Date(instanceData.instanceStartDate), new Date());

  const hasStarted = diffInMinutes < 0;
  const startsInLessThanFiveMinutes = diffInMinutes <= 5;
  const startsInMoreThanFiveMinutes = diffInMinutes > 5;
  const startedMoreThanTenMinutesAgo = diffInMinutes < -10;

  const canRedirect =
    userIsRegisteredForThisInstance && hasStarted && !startedMoreThanTenMinutesAgo;

  const aboutToStart = userIsRegisteredForThisInstance && startsInLessThanFiveMinutes;

  const alreadyOver = userIsRegisteredForThisInstance && startedMoreThanTenMinutesAgo;

  return (
    <Box height="100vh">
      <AbsoluteCenter>
        <Box
          backgroundColor="white"
          border="1px solid"
          borderColor="neutral.100"
          borderRadius="24px"
          paddingY={16}
          paddingX={20}
          boxShadow="5px 5px 11px -6px rgba(0,0,0,0.2)"
        >
          {!userIsRegisteredForThisInstance && <NotRegistered instance={instanceData} />}

          {canRedirect && (
            <WorkshopRedirect
              instance={instanceData}
              mutate={() =>
                registerForWorkshopAsync({
                  onlineLocation: instanceData.onlineLocation,
                  instanceId: instanceData.instanceId,
                })
              }
            />
          )}

          {userIsRegisteredForThisInstance && startsInMoreThanFiveMinutes && (
            <WorkshopStartsMoreThanFive instance={instanceData} />
          )}

          {aboutToStart && !alreadyOver && !hasStarted && (
            <WorkshopStartsLessThanFive
              instance={instanceData}
              mutate={() =>
                registerForWorkshopAsync({
                  onlineLocation: instanceData.onlineLocation,
                  instanceId: instanceData.instanceId,
                })
              }
            />
          )}

          {alreadyOver && <WorkshopOver instance={instanceData} />}
        </Box>
      </AbsoluteCenter>
    </Box>
  );
}
