import { FullRelatedWorkshop, Workshop, WorkshopInstance } from '@/client/types/content/Workshop';
import { UserWorkshop, UserWorkshopStats } from '@/client/types/content/workshops/UserWorkshop';

import { getRelatedWorkshops } from './shared/getRelatedWorkshops';
import { isBefore } from 'date-fns';
import { learnApiClient } from '../../clients/learnApiClient';

const getWorkshopInstances = async (
  workshopId: string | undefined,
): Promise<WorkshopInstance[]> => {
  const response = await learnApiClient.get<WorkshopInstance[]>(
    `workshop-instances?fields=workshop,start_date,end_date,viewing_timezone,seats,allow_waitlist,waitlist_seats,host,location,online,online_location,online_location_label,site,group,zoom_meeting_id,recordings,allow_auto_attendance&sort=start_date,1&workshop=${workshopId}&company-populate=domain`,
  );

  return response.data;
};

const getUserWorkshops = async (
  userId: string,
  workshopId: string | undefined,
): Promise<UserWorkshop[]> => {
  const response = await learnApiClient.get(
    `user-workshops?fields=company,user,workshop,workshop_instance,active_status,status_history&user=${userId}&workshop=${workshopId}`,
  );

  return response.data;
};

const getSingleWorkshopInstance = async (
  instanceId: string | undefined,
): Promise<WorkshopInstance> => {
  const response = await learnApiClient.get(
    `workshop-instances/${instanceId}?fields=workshop,start_date,end_date,viewing_timezone,seats,allow_waitlist,waitlist_seats,host,location,online,online_location,online_location_label,site,group,zoom_meeting_id,zoom_settings,recordings`,
  );

  return response.data;
};

const getWorkshop = async (
  userId: string,
  workshopId: string | undefined,
): Promise<{
  workshop: Workshop;
  workshopInstances: WorkshopInstance[];
  userWorkshops: UserWorkshop[];
  isRegistered: boolean;
  registeredInstances: UserWorkshop[];
  isWaiting: boolean;
  waitingInstances: UserWorkshop[];
  attendedInstances: UserWorkshop[];
  ratingInstanceId: string | undefined;
  triggerRating: boolean;
  relatedWorkshops: FullRelatedWorkshop[];
  registeredInstance: WorkshopInstance | undefined;
  waitingInstance: WorkshopInstance | undefined;
  hasAttended: boolean;
  futureInstances: WorkshopInstance[];
}> => {
  const workshopResponse = await learnApiClient.get<Workshop>(
    `workshops/${workshopId}?fields=title,description,image,banner_image,related_workshops,cover_justification,featured_justification,tags,private,privacy_departments,privacy_locations,privacy_teams,privacy_grades,privacy_org_levels,rating_configuration,average_rating,allow_comments,groups,surveys,allow_reregistration,require_attendance_for_reregistration&related_workshops-populate=title,type,image,banner_image`,
  );
  const workshopInstancesResponse = await getWorkshopInstances(workshopId);
  const userWorkshopsResponse = await getUserWorkshops(userId, workshopId);

  const futureInstances = workshopInstancesResponse.filter((instance) =>
    isBefore(new Date(Date.now()), new Date(instance.end_date)),
  );

  let relatedWorkshops: FullRelatedWorkshop[] = [];

  if (
    workshopResponse.data.related_workshops &&
    workshopResponse.data.related_workshops.length > 0
  ) {
    relatedWorkshops = await getRelatedWorkshops(workshopResponse.data.related_workshops);
  }

  let isRegistered = false;
  let registeredInstances: UserWorkshop[] = [];
  let isWaiting = false;
  let waitingInstances: UserWorkshop[] = [];
  let ratingInstanceId;
  let registeredInstance;
  let waitingInstance;

  const userRegisteredInstances = userWorkshopsResponse.filter(
    (instance: UserWorkshop) => instance.active_status === 'registered',
  );

  const userWaitingInstances = userWorkshopsResponse.filter(
    (instance: UserWorkshop) => instance.active_status === 'waiting',
  );

  if (userRegisteredInstances.length > 0) {
    isRegistered = true;
    registeredInstances = userRegisteredInstances;
  }

  if (userWaitingInstances.length > 0) {
    isWaiting = true;
    waitingInstances = userWaitingInstances;
  }

  let attendedInstances: UserWorkshop[] = [];

  if (userWorkshopsResponse && userWorkshopsResponse.length) {
    attendedInstances = userWorkshopsResponse.filter((item) => {
      if (item.active_status) {
        if (item.active_status === 'attended') {
          return item;
        }
      }
    });
  }

  return {
    workshop: workshopResponse.data,
    workshopInstances: workshopInstancesResponse,
    userWorkshops: userWorkshopsResponse,
    isRegistered,
    registeredInstances,
    isWaiting,
    triggerRating: attendedInstances.length > 0,
    hasAttended: attendedInstances.length > 0,
    waitingInstances,
    attendedInstances,
    ratingInstanceId,
    relatedWorkshops,
    registeredInstance,
    waitingInstance,
    futureInstances,
  };
};

const register = async (
  userId: string,
  workshopId: string | undefined,
  workshopInstanceId: string | undefined,
): Promise<void> =>
  learnApiClient.post(
    `user-workshops/register/user/${userId}/workshop/${workshopId}/instance/${workshopInstanceId}`,
  );

const unregister = async (
  userId: string,
  registeredInstanceId: string | undefined,
): Promise<void> => {
  const response = await learnApiClient.post(
    `user-workshops/withdraw/user/${userId}/user-workshop/${registeredInstanceId}`,
  );

  return response.data;
};

const unregisterWithNewRegister = async (
  userId: string,
  workshopId: string | undefined,
  oldInstanceId: string | undefined,
  newInstanceId: string | undefined,
) =>
  learnApiClient
    .post(`user-workshops/withdraw/user/${userId}/user-workshop/${oldInstanceId}`)
    .then(() =>
      learnApiClient.post(
        `user-workshops/register/user/${userId}/workshop/${workshopId}/instance/${newInstanceId}`,
      ),
    )
    .catch((err) => console.log(err));

const joinWaitlist = async (
  userId: string,
  workshopId: string | undefined,
  workshopInstanceId: string | undefined,
): Promise<void> =>
  learnApiClient.post(
    `user-workshops/wait/user/${userId}/workshop/${workshopId}/instance/${workshopInstanceId}`,
  );

const leaveWaitlist = async (
  userId: string,
  waitingInstanceId: string | undefined,
): Promise<void> =>
  learnApiClient.post(`user-workshops/withdraw/user/${userId}/user-workshop/${waitingInstanceId}`);

const getAvailableInstanceSeats = async (
  workshopId: string | undefined,
  workshopInstance: WorkshopInstance | undefined,
): Promise<any> => {
  if (!workshopId || !workshopInstance) {
    return;
  }

  const response = await learnApiClient.get<UserWorkshop[]>(
    `user-workshops?fields=company,user,workshop,workshop_instance,active_status,status_history&workshop=${workshopId}&workshop_instance=${workshopInstance._id}`,
  );

  const userWorkshops = response.data;

  let availableSeats = workshopInstance.seats;
  let availableWaitlistSeats = workshopInstance.waitlist_seats;

  if (userWorkshops && userWorkshops.length) {
    for (let i = 0; i < userWorkshops.length; i += 1) {
      if (!userWorkshops[i]) return;

      if (userWorkshops[i].active_status && userWorkshops[i].active_status === 'registered') {
        availableSeats -= 1;
      }
      if (userWorkshops[i].active_status && userWorkshops[i].active_status === 'waiting') {
        availableWaitlistSeats -= 1;
      }
    }
  }

  return {
    availableSeats,
    availableWaitlistSeats: workshopInstance.allow_waitlist ? availableWaitlistSeats : 0,
  };
};

const searchWorkshops = async (searchQuery: any, pageParam: number) => {
  const response = await learnApiClient.get(`workshops-search`, {
    params: { page: pageParam, query: searchQuery },
  });
  return {
    items: response.data.results,
    nextPage: response.data.next_page || undefined,
  };
};

const getWorkshopInstancesAndUserWorkshops = async (
  startDate: Date,
  endDate: Date,
): Promise<any> => {
  const workshopIds: any = [];
  const instancesDict: Record<string, any> = {};

  const response = await learnApiClient.get(`workshops-search`, {
    params: { query: { startDateMin: startDate, startDateMax: endDate } },
  });

  response.data.results?.map((instance: any) => {
    instancesDict[instance._id] = instance;
    workshopIds.push(instance._id);
    instancesDict[instance._id].registered = instance.status === 'registered';

    return instance;
  });

  return Object.values(instancesDict);
};

const getUserWorkshopStats = async () => {
  const response = await learnApiClient.get<UserWorkshopStats>('workshops/stats');
  return response.data;
};

// ! Get workshop instance details and workshop name by instanceId
const getInstanceDetailsByInstanceId = async (
  userId: string,
  instanceId: string | undefined,
): Promise<{
  workshopName: string;
  workshopId: string;
  instanceId: string;
  instanceStartDate: string;
  instanceEndDate: string;
  onlineLocation: string;
  userWorkshops: UserWorkshop[];
}> => {
  const instanceResponse = await getSingleWorkshopInstance(instanceId);

  const workshopId = instanceResponse.workshop;

  const workshopResponse = await learnApiClient.get<Workshop>(
    `workshops/${workshopId}?fields=title`,
  );

  const userWorkshopsResponse = await getUserWorkshops(userId, workshopId);

  return {
    workshopName: workshopResponse.data.title,
    workshopId,
    instanceId: instanceResponse._id,
    instanceStartDate: instanceResponse.start_date,
    instanceEndDate: instanceResponse.end_date,
    onlineLocation: instanceResponse.online_location,
    userWorkshops: userWorkshopsResponse,
  };
};

const WorkshopService = {
  getWorkshop,
  register,
  unregister,
  unregisterWithNewRegister,
  getAvailableInstanceSeats,
  joinWaitlist,
  leaveWaitlist,
  searchWorkshops,
  getWorkshopInstancesAndUserWorkshops,
  getUserWorkshopStats,
  getSingleWorkshopInstance,
  getInstanceDetailsByInstanceId,
};

export default WorkshopService;
