import { memo, useState } from 'react';
import { Badge, Button, ButtonGroup, Center, Flex, HStack, Text } from '@chakra-ui/react';
import { Action, AuthType, init } from '@thoughtspot/visual-embed-sdk';
import { getAuthToken } from '@/client/services/api/admin/reports/ContinuInsightsService';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useToastStore } from '@/client/services/state/toastStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { AppEmbed, Page } from '@thoughtspot/visual-embed-sdk/react';
import { useNavigate } from 'react-router-dom';
import ContinuInsightsService from '@/client/services/api/admin/continu-insights/ContinuInsightsService';
import { useQuery } from '@tanstack/react-query';
import { AngleCircleLeftGhost } from '@/client/components/icons/ContinuIcons';

const Embed = memo(
  ({
    aie,
    renderFn,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dependency,
  }: {
    aie: boolean | undefined;
    renderFn: () => void;
    dependency: boolean;
  }) => {
    // https://developers.thoughtspot.com/docs/actions#liveboardv2-action
    const visibleActions = [
      Action.Explore,
      Action.Download,
      Action.DownloadAsCsv,
      Action.AddFilter,
      Action.CrossFilter,
      Action.RemoveCrossFilter,
      Action.ConfigureFilter,
      Action.DrillDown,
      Action.ShowUnderlyingData,
      Action.Pin,
      Action.CreateLiveboard,
      Action.Remove,
      Action.AxisMenuFilter,
      Action.AxisMenuSort,
    ];
    // TODO not allowing for now, enable when we allow this
    // if (aie) {
    //   visibleActions.push(Action.AskAi);
    // }
    return (
      <AppEmbed
        frameParams={{ height: '100vh' }}
        pageId={Page.Liveboards}
        visibleActions={visibleActions}
        showPrimaryNavbar={false}
        isLiveboardHeaderSticky
        onError={(error) => console.error('Error Loading Continu Insights', error)}
        onLiveboardRendered={renderFn}
        fullHeight
        hideOrgSwitcher
        enableAskSage
      />
    );
  },
  // only return false and re-render when navigating back to main liveboards
  (prevProps, nextProps) => prevProps.dependency === nextProps.dependency,
);

export default function ContinuInsights() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { allowance } = useAccessHelper();
  const { setToast } = useToastStore();
  const { config } = useConfigStore();
  const [liveboardRendered, setLiveboardRendered] = useState(false);
  const [dependency, setDependency] = useState(false);

  if (!allowance.continuInsights) {
    setToast({
      show: true,
      status: 'error',
      title: t('continuInsights.noAccess'),
    });
    navigate('/explore');
  }

  const { data: isAIEnabled } = useQuery({
    enabled: allowance.continuInsights,
    queryKey: ['checkAiEnabled'],
    queryFn: () => ContinuInsightsService.checkAiEnabled(),
  });

  useDocumentTitle(t('continuInsights.header'));

  init({
    thoughtSpotHost: config.continuInsightsHost,
    authType: AuthType.TrustedAuthTokenCookieless,
    getAuthToken: async () => getAuthToken(),
    disableLoginFailurePage: true,
    customizations: {
      content: {
        strings: {
          Sage: 'Continu AI',
          Liveboard: 'Dashboard',
          Liveboards: 'Dashboards',
        },
      },
      style: {
        customCSS: {
          variables: {
            '--ts-var-root-background': '#FFFFFF',
            '--ts-var-viz-box-shadow': '0px 6px 10px 0px #0000000A',
          },
          // this might break in future versions of TS (https://developers.thoughtspot.com/docs/custom-css#supported-variables:~:text=%27%7Bselector2%7D%27...%0A%7D-,WARNING,-While%20the%20rules_UNSTABLE)
          rules_UNSTABLE: {
            // remove border from dashboard header
            '.pinboard-header-module__pinboardHeaderContainer': {
              border: 'none!important',
              'margin-left': '0px',
            },
            '.pinboard-header-module__embedPinboardHeader': {
              border: 'none!important',
            },
            // because of copy changes, need to shift header
            '.library-header-module__libraryHeader': {
              'padding-left': '24px',
            },
            // hide add liveboard button on main page
            '.home-library-module__addLiveboardContainer': {
              display: 'none',
            },
            // hidefooter
            '.footer-module__footerLogo': {
              display: 'none',
            },
          },
        },
      },
    },
  });

  const goLiveboardHome = () => {
    setLiveboardRendered(false);
    setDependency((prev) => !prev);
  };

  return (
    <>
      <Flex
        height="auto"
        width="auto"
        position="relative"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="neutral.0"
        paddingX={4}
        paddingY={6}
        borderBottom="1px solid"
        borderColor="neutral.100"
      >
        <HStack paddingLeft={2}>
          <Text fontSize="xl" lineHeight="1.2" fontWeight="500" margin="2 0 2 2">
            {t('continuInsights.header')}
          </Text>
          <Badge variant="tag">{t('global.label.beta')}</Badge>
        </HStack>
        {liveboardRendered && (
          <Center>
            <ButtonGroup size="md">
              <Button variant="ghost" size="md" onClick={() => goLiveboardHome()}>
                <AngleCircleLeftGhost />
                <Text fontWeight="500">{t('continuInsights.allDashboards')}</Text>
              </Button>
            </ButtonGroup>
          </Center>
        )}
      </Flex>
      {isAIEnabled !== undefined && (
        <Embed
          aie={isAIEnabled}
          renderFn={() => {
            setLiveboardRendered(true);
          }}
          dependency={dependency}
        />
      )}
    </>
  );
}
