import {
  Box,
  Checkbox,
  Container,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import DashboardAssignmentsList from '../components/lists/dashboard/DashboardAssignmentsList';
import DashboardBadgesList from '../components/lists/dashboard/DashboardBadgesList';
import DashboardCertificatesList from '../components/lists/dashboard/DashboardCertificatesList';
import DashboardDates from '../components/data-display/calendar/DashboardDates';
import DashboardExternalCoursesList from '../components/lists/dashboard/DashboardExternalCoursesList';
import DashboardLearningTracksList from '../components/lists/dashboard/DashboardLearningTracksList';
import DashboardPurchasesList from '../components/lists/dashboard/DashboardPurchasesList';
import DashboardService from '@/client/services/api/DashboardService';
import DashboardWorkshopsList from '../components/lists/dashboard/DashboardWorkshopsList';
import DoughnutChart from '../components/data-display/charts/DoughnutChart';
import ErrorAlert from '../components/data-display/ErrorAlert';
import Loading from '@/client/components/media/Loading';
import OutlineButton from '../components/shared/buttons/OutlineButton';
import UserStatsSection from '../components/data-display/dashboard/UserStatsSection';
import WorkshopAssignmentCalendar from '@/client/components/data-display/calendar/WorkshopAssignmentCalendar';
import { useAuthStore } from '@/client/services/state/authStore';
import { useDashboardStore } from '../services/state/routeState/dashboardStore';
import useDocumentTitle from '../utils/useDocumentTitle';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function Dashboard() {
  useDocumentTitle('My Dashboard');
  const [searchParams] = useSearchParams();
  const { authConfig } = useAuthStore();
  const navigate = useNavigate();
  const { user, company } = authConfig;
  const { feature_flags: featureFlags } = company;
  const { t } = useTranslation();
  const { selectedTabIndex, setSelectedTabIndex } = useDashboardStore();
  const [progressAllTime, setProgressAllTime] = useState<boolean>(false);

  const renderAiAgent = featureFlags.enable_ai_agent;

  const setIndexBasedOnName = (name: string) => {
    switch (name) {
      case 'assignments':
        return 0;
      case 'learning-tracks':
        return 1;
      case 'workshops':
        return 2;
      case 'external-courses':
        return 3;
      case 'certificates':
        return 4;
      case 'badges':
        return 5;
      case 'purchases':
        return 6;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const activeTab = searchParams.get('activeTab');

    if (activeTab) {
      setSelectedTabIndex(setIndexBasedOnName(activeTab));
    }
  }, [searchParams]);

  const { data: trackStats } = useQuery({
    queryKey: ['track-stats', user._id],
    queryFn: () => DashboardService.getCompletedUserTracks(user._id),
  });

  const { isLoading, isError, data } = useQuery({
    queryKey: ['user-workshops', user._id],
    queryFn: () => DashboardService.getUserWorkshops(user._id),
  });

  const {
    isLoading: assignmentsLoading,
    isError: isAssignmentsError,
    data: assignments,
  } = useQuery({
    queryKey: ['user-assignments', user._id],
    queryFn: () => DashboardService.getUserAssignments(user._id),
  });

  const progressLabels = [
    t('modules.userAssignmentsChart.checkboxLabelLastThirty'),
    t('modules.userAssignmentsChart.checkboxLabelAllTime'),
  ];

  const showStats = () => {
    if (
      (assignments && assignments.completedAssignments.length) ||
      (assignments && assignments.openAssignments.length)
    ) {
      return true;
    }
    return false;
  };

  if (assignmentsLoading || isLoading) return <Loading />;

  if (isAssignmentsError || isError) return <ErrorAlert title="Error loading dashboard" />;

  return (
    <Container minHeight="100vh" minWidth="100%" backgroundColor="brand.mist" position="relative">
      <Box
        bgColor="brand.primary"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="600px"
        overflow="hidden"
        zIndex="0"
      >
        <Box
          position="absolute"
          bottom="0"
          left="0"
          bgGradient="linear(transparent 10%, brand.mist 90%)"
          width="100%"
          height="200px"
          zIndex="3"
        />

        <Box
          position="absolute"
          bottom="0"
          left="0"
          bgColor="blackAlpha.600"
          width="100%"
          height="100%"
          zIndex="2"
        />

        {user.banner_image && (
          <Box
            bgImage={user.banner_image}
            position="absolute"
            top="-2%"
            left="-2%"
            width="104%"
            height="100%"
            z-index="0"
            backgroundSize="cover"
            backgroundPosition="center center"
            filter="blur(7px)"
            zIndex="1"
          />
        )}
      </Box>

      {trackStats && (
        <Box maxWidth="1200px" margin="0 auto">
          <UserStatsSection
            name={user.full_name}
            stats={[
              {
                statCount: trackStats.incomplete || 0,
                counterLabel: t('dashboard.stats.open_learning_tracks'),
              },
              {
                statCount: trackStats.complete || 0,
                counterLabel: t('dashboard.stats.completed_learning_tracks'),
              },
            ]}
            avatarSrc={user.image}
            currentUser
          />
        </Box>
      )}

      <Flex
        direction={{ base: 'column', xl: 'row' }}
        minHeight="100%"
        width="1400px"
        maxWidth="100%"
        margin="0 auto"
        position="relative"
        zIndex={2}
      >
        <Box
          padding={4}
          marginRight={{ base: 0, lg: 1 }}
          backgroundColor="white"
          flexGrow="1"
          flexShrink="1"
          flexBasis="800px"
        >
          {renderAiAgent && (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="neutral.0"
              border="1px solid"
              borderColor="neutral.100"
              borderRadius="8px"
              padding={6}
              marginBottom={6}
            >
              <Stack>
                <Text fontSize="18px" fontWeight={600} color="baseBlack">
                  {t('aiAgent.try')}
                </Text>

                <Text fontSize="14px" fontWeight={400} color="baseBlack">
                  {t('aiAgent.search')}
                </Text>
              </Stack>

              <OutlineButton
                size="xs"
                onClick={() =>
                  navigate('/ai-agent', {
                    state: {
                      backRoute: '/dashboard',
                      backLinkText: t('dashboard.backToDashboard'),
                    },
                  })
                }
              >
                {t('aiAgent.startChatting')}
              </OutlineButton>
            </Flex>
          )}

          <Flex alignItems="center" justifyContent="space-between">
            <Heading as="h2" fontSize="sm" textTransform="uppercase" marginBottom={4}>
              {t('dashboard.my_content')}
            </Heading>
          </Flex>

          <Tabs
            isLazy
            maxWidth="full"
            onChange={(tabIndex: number) => setSelectedTabIndex(tabIndex)}
            defaultIndex={selectedTabIndex}
          >
            <TabList
              overflowX="auto"
              overflowY="revert"
              marginBottom={2}
              paddingBottom="3px"
              whiteSpace="nowrap"
            >
              {user.is_purchaser && <Tab>{t('dashboard.mySubscriptions')}</Tab>}
              <Tab>{t('dashboard.assignments')}</Tab>
              <Tab>{t('dashboard.learning_tracks')}</Tab>
              <Tab>{t('global.contentTypes.workshops')}</Tab>
              <Tab>{t('dashboard.external_courses')}</Tab>
              <Tab>{t('dashboard.certificates')}</Tab>
              {company.feature_flags.enable_badges && <Tab>{t('dashboard.badges')}</Tab>}
            </TabList>

            <TabPanels>
              {user.is_purchaser && (
                <TabPanel>
                  <DashboardPurchasesList userId={user._id} />
                </TabPanel>
              )}

              <TabPanel>
                <DashboardAssignmentsList
                  openAssignments={assignments.openAssignments}
                  completedAssignments={assignments.completedAssignments}
                />
              </TabPanel>

              <TabPanel>
                <DashboardLearningTracksList userId={user._id} />
              </TabPanel>
              <TabPanel>
                <DashboardWorkshopsList
                  attendedWorkshops={data.attended}
                  registeredWorkshops={data.registered}
                />
              </TabPanel>

              <TabPanel>
                <DashboardExternalCoursesList userId={user._id} />
              </TabPanel>

              <TabPanel>
                <DashboardCertificatesList userId={user._id} />
              </TabPanel>

              {company.feature_flags.enable_badges && (
                <TabPanel>
                  <DashboardBadgesList userId={user._id} />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Box>

        <Box
          marginX={{ base: 0, xl: 4 }}
          flexGrow="1"
          flexShrink="1"
          flexBasis="250px"
          position="relative"
          zIndex="-1"
          backgroundColor={{ base: 'white', xl: 'brand.mist' }}
        >
          {!showStats() && (
            <Text
              fontSize="lg"
              fontWeight="bold"
              padding={8}
              textAlign="center"
              color="blackAlpha.600"
            >
              {t('modules.userAssignmentsChart.noAssignments')}
            </Text>
          )}

          {showStats() && (
            <Box
              backgroundColor="white"
              padding={4}
              maxW={{ base: '410px', xl: '100%' }}
              margin={{ base: '0 auto', xl: 'auto' }}
            >
              <Heading as="h2" fontSize="sm" textTransform="uppercase" marginBottom={0}>
                {t('dashboard.stats.user_progress')} - {progressLabels[progressAllTime ? 1 : 0]}
              </Heading>

              <DoughnutChart
                labelOne={t('modules.userAssignmentsChart.openAssignments')}
                labelTwo={t('modules.userAssignmentsChart.completedAssignments')}
                valueOne={assignments.openAssignments.length}
                valueTwo={
                  progressAllTime
                    ? assignments.completedAssignments.length
                    : assignments.completedLastThirtyCount
                }
                isLoading={assignmentsLoading}
                updateMode="active"
              />

              <Checkbox onChange={() => setProgressAllTime(!progressAllTime)}>
                {t('global.actions.show')} {progressLabels[progressAllTime ? 0 : 1]}
              </Checkbox>

              <Flex minWidth="200px" marginTop={0} color="blackAlpha.700">
                <Box
                  borderTop="3px solid gray"
                  borderRight="1px solid gray"
                  borderColor="blackAlpha.300"
                  padding={2}
                  textAlign="center"
                >
                  {assignments.openAssignments.length}

                  <br />

                  {t('modules.userAssignmentsChart.openAssignments')}
                </Box>

                <Box
                  borderTop="3px solid black"
                  borderColor="brand.secondary"
                  padding={2}
                  textAlign="center"
                >
                  {assignments.completedAssignments.length}

                  <br />

                  {t('modules.userAssignmentsChart.completedAssignments')}
                </Box>
              </Flex>
            </Box>
          )}

          {showStats() && (
            <WorkshopAssignmentCalendar
              assignments={assignments.openAssignments}
              workshops={data.registered}
              DayRenderer={DashboardDates}
              showHeading
            />
          )}
        </Box>
      </Flex>
    </Container>
  );
}
