import { Box, Button, Flex, Icon } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import AssessmentAwaitingGrade from '@/client/components/content/assessments/AssessmentAwaitingGrade';
import AssessmentScore from '@/client/components/content/assessments/AssessmentScore';
import { BiCollapse } from 'react-icons/bi';
import ContentService from '@/client/services/api/ContentService';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import FileUploadAssessment from './FileUploadAssessment';
import Loading from '@/client/components/media/Loading';
import TextAssessment from '@/client/components/content/assessments/textAssessment/TextAssessment';
import VideoAssessment from './VideoAssessment';
import ViewActions from '@/client/components/overlay/ViewActions';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useGetAssessment } from '@/client/services/hooks/content/assessments/useGetAssessment';
import { useGetUserAssessment } from '@/client/services/hooks/content/assessments/useGetUserAssessment';
import { useSessionTracking } from '@/client/services/hooks/useSessionTracking';
import { useTextAssessmentStore } from '@/client/services/state/content/textAssessmentStore';
import { useTranslation } from 'react-i18next';

interface AssessmentViewProps {
  trackId?: string;
  journeyId?: string;
  contentId?: string;
  journeyNavigateAction?: () => void;
  needsContextSelection?: boolean;
  contextId?: string;
  sendCompleteContent?: Function;
  previewData?: any;
}

export default function AssessmentView({
  trackId = undefined,
  journeyId = undefined,
  contentId = undefined,
  journeyNavigateAction = undefined,
  needsContextSelection = false,
  contextId = undefined,
  sendCompleteContent,
  previewData = undefined,
}: AssessmentViewProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const [retakingAssessment, setRetakingAssessment] = useState(false);
  const inTrack = !!trackId;
  const inJourney = !!journeyId;
  const { setAssessment, setUserAssessment } = useTextAssessmentStore();

  const { sendSessionTime } = useSessionTracking(contentId, 'assessment');

  const { data: assessment, isLoading, isError } = useGetAssessment(contentId);
  const { setAssessmentPreviewModalIsOpen } = useCreateStore();

  const {
    data: userAssessment,
    isLoading: userAssessmentLoading,
    isError: isUserAssessmentError,
    refetch: refetchUserAssessent,
  } = useGetUserAssessment(contextId);

  useEffect(() => {
    if (previewData) {
      return;
    }
    if (assessment && !assessment.video_assessment && !assessment.file_assessment) {
      setAssessment(assessment);
    }

    if (userAssessment) {
      setUserAssessment(userAssessment);
    }
  }, [assessment, userAssessment]);

  useEffect(() => {
    if (previewData) {
      return;
    }
    if (!needsContextSelection) {
      AnalyzeService.postViews(contentId, 'assessment');
      ContentService.postViews(contentId, 'assessment');
    }

    return () => {
      sendSessionTime();
    };
  }, [needsContextSelection]);

  const handleCompleteContent = () => {
    if (!needsContextSelection && sendCompleteContent) {
      return sendCompleteContent({});
    }

    if (userAssessment) {
      let contexts: [{ key: string; value: string | undefined }] | [] = [];
      if (inJourney) {
        contexts = [{ key: 'journey', value: journeyId }];
      }
      if (inTrack) {
        contexts = [{ key: 'track', value: trackId }];
      }

      return ContentService.completeContent({
        id: contentId,
        contentType: 'assessment',
        contexts,
      });
    }
  };

  if ((isLoading || userAssessmentLoading) && !previewData) {
    return <Loading />;
  }

  if (isError || isUserAssessmentError) return <ErrorAlert title="Unable to get assessment" />;

  if (!previewData && (!assessment || !userAssessment))
    return <ErrorAlert title="Unable to get assessment" />;

  if (assessment && userAssessment) {
    // TODO differentiate between userAssessments from different possible contexts
    if (userAssessment.awaiting_manual_grade) return <AssessmentAwaitingGrade />;

    if (userAssessment.passed) {
      return (
        <AssessmentScore
          assessment={assessment}
          userAssessment={userAssessment}
          setRetakingAssessment={setRetakingAssessment}
          hasPassed
          user={user}
          inTrack={inTrack}
          inJourney={inJourney}
          backToJourney={journeyNavigateAction}
        />
      );
    }

    if (!retakingAssessment) {
      if (
        (!userAssessment.awaiting_manual_grade &&
          userAssessment.scores &&
          userAssessment.scores.length > 0 &&
          !userAssessment.passed &&
          !userAssessment.in_progress_responses) ||
        userAssessment.scores.length - 1 === assessment.number_of_retakes
      )
        return (
          <AssessmentScore
            assessment={assessment}
            userAssessment={userAssessment}
            hasPassed={false}
            user={user}
            setRetakingAssessment={setRetakingAssessment}
            inTrack={inTrack}
          />
        );
    }
  }

  return (
    <Box>
      <Flex width="full">
        {previewData && (
          <Flex width="full" justifyContent="flex-end" padding={4}>
            <Button
              variant="createCancel"
              leftIcon={<Icon as={BiCollapse} />}
              onClick={() => setAssessmentPreviewModalIsOpen(false)}
            >
              {t('authoring.livePreview.returnToEditing')}
            </Button>
          </Flex>
        )}
      </Flex>
      {(assessment?.video_assessment || previewData?.video_assessment) && (
        <VideoAssessment
          assessment={previewData || assessment}
          userAssessment={userAssessment!}
          setRetakingAssessment={setRetakingAssessment}
          refetch={refetchUserAssessent}
          handleCompleteContent={handleCompleteContent}
          isPreview={!!previewData}
        />
      )}

      {(assessment?.file_assessment || previewData?.file_assessment) && (
        <FileUploadAssessment
          assessment={previewData || assessment}
          userAssessment={userAssessment!}
          setRetakingAssessment={setRetakingAssessment}
          handleCompleteContent={handleCompleteContent}
          isPreview={!!previewData}
        />
      )}

      {((assessment && !assessment.video_assessment && !assessment.file_assessment) ||
        (previewData && !previewData.video_assessment && !previewData.file_assessment)) && (
        <TextAssessment
          refetchUserAssessment={refetchUserAssessent}
          setRetakingAssessment={setRetakingAssessment}
          inTrack={inTrack}
          inJourney={inJourney}
          handleCompleteContent={handleCompleteContent}
          previewData={previewData}
        />
      )}

      {!inTrack && !inJourney && <ViewActions bottomOffset={50} buttons={['gototop']} />}
    </Box>
  );
}
