/* eslint-disable react/jsx-props-no-spreading */

import {
  Content,
  ContentTypes,
  FileExtras,
  PartnerPermissionTypes,
} from "@/client/services/api/graphql/gql/graphql";
import { FormProvider, useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";

import AuthoringLivePreview from "@/client/components/admin/create/content/AuthoringLivePreview";
import type { ContentFormValues } from "@/client/types/admin/content-authoring/ContentFormValues";
import CreateLayout from "@/client/components/admin/create/shared/layout/CreateLayout";
import EditFileForm from "@/client/components/admin/create/content/forms/files/EditFileForm";
import TrackStateAlert from "@/client/components/admin/create/content/overlay/TrackStateAlert";
import { useAccessHelper } from "@/client/services/hooks/auth/useAccessHelper";
import useDocumentTitle from "@/client/utils/useDocumentTitle";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface FileFormValues {
  fileUploadType: "upload" | "embed";
  fileDetails: { name: string; size: number } | null;
}

export type FileFormData = Content &
  FileExtras &
  FileFormValues &
  ContentFormValues;

export default function EditFile() {
  const { t } = useTranslation();
  useDocumentTitle(t("authoring.documentTitle.editFile"));
  const { id } = useParams();
  const { isAllowed } = useAccessHelper();

  const allowance = {
    create_file: isAllowed(
      ["admin", "content", "all_create"],
      [["admin", "content", "files_create"]],
      ["admin", "creator", "collaborator"]
    ),
    edit_file: isAllowed(
      ["admin", "content", "all_edit"],
      [
        ["admin", "content", "files_edit"],
        ["admin", "content", "my_content_edit"],
      ],
      ["admin", "creator", "collaborator"]
    ),
  };

  if ((id && !allowance.edit_file) || (!id && !allowance.create_file)) {
    return <Navigate to="/explore" replace />;
  }

  const fileDefaultValues: FileFormData = {
    archived: false,
    creator: "",
    contentType: ContentTypes.File,
    fileUploadType: "upload", // 'upload' | 'embed'
    fileDetails: null,
    id: "",
    company: "",
    title: "",
    duration: 0,
    private: false,
    privacyCollaborators: [],
    privacyLocations: [],
    privacyDepartments: [],
    privacyTeams: [],
    privacyOrgLevels: [],
    privacyGrades: [],
    privacyGroups: [],
    linkedCategory: [],
    embed: false,
    embedSettings: null,
    downloadable: false,
    link: "",
    description: "",
    exploreHide: true,
    draft: true,
    source: null,
    sourceId: null,
    sourceIdV2: null,
    tags: [],
    approvalRequired: false,
    approved: false,
    useGenerativeAi: false,
    allowComments: false,
    signatureRequired: false,
    partnerPermissions: PartnerPermissionTypes.Tenant,
    surveys: [],
    author: "",
    ratingConfiguration: {
      messaging: {
        individuals: [],
        slackChannels: [],
      },
      allowRating: false,
      allowFeedback: false,
      allowEdits: false,
      notifyFeedbackOnly: false,
      showUsersRating: false,
      totalValue: 5,
    },
    enable_collaborators: false,
    enable_surveys: false,
    enable_segmentation: false,
  };

  const methods = useForm<FileFormData>({
    defaultValues: fileDefaultValues,

    mode: "onBlur",
  });

  const { watch, setValue, formState, clearErrors } = methods;

  const fileUploadType = watch("fileUploadType");

  useEffect(() => {
    setValue("embed", fileUploadType === "embed");
  }, [fileUploadType]);

  const title = watch("title");
  const author = watch("author");
  const description = watch("description");
  const { errors } = formState;

  useEffect(() => {
    if (errors.title && title !== "") clearErrors("title");

    if (errors.author && author !== "") clearErrors("author");

    if (errors.description && description !== "") clearErrors("description");
  }, [title, author, description]);

  return (
    <FormProvider {...methods}>
      <form>
        <CreateLayout
          previewElement={
            <AuthoringLivePreview
              contentType="file"
              titlePlaceholder={t(
                "authoring.file.livePreview.titlePlaceholder"
              )}
            />
          }
          formElement={<EditFileForm />}
        />
      </form>

      <TrackStateAlert contentType="file" />
    </FormProvider>
  );
}
