import type {
  CompleteContentPayload,
  CompletionMutationVariables,
} from '@/client/types/content/completion/ContentCompletion';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

import ContentService from '@/client/services/api/ContentService';
import ContextSelector from '@/client/components/overlay/ContextSelector';
import ContextService from '@/client/services/api/ContextService';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

/**
 * Wrapped content types:
 * Article
 * ContentFile
 * Video
 * Scorm
 * LearningTrack
 * Assessment
 *
 * Unwrapped content types:
 * Workshop
 * MultiSessionWorkshop
 * ImportedContent
 * Journey
 */

interface ContentWrapperProps {
  content: any;
  contentType: string;
  contentId?: string | null;
  selectorDisabled?: boolean;
  trackId?: string | null;
  journeyId?: string | null;
  onCompleteContent?: () => void;
  journeyNavigateAction?: () => Promise<void>;
  parentContextId?: string;
}

export default function ContentWrapper({
  content,
  contentType,
  contentId = null,
  selectorDisabled = false,
  trackId = null,
  journeyId = null,
  onCompleteContent = undefined,
  journeyNavigateAction = undefined,
  parentContextId = undefined,
}: ContentWrapperProps) {
  const { t } = useTranslation();
  let { id } = useParams();
  const { setToast } = useToastStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [completionContextId, setCompletionContextId] = useState<string | null>(null);
  const [needsContextSelection, setNeedsContextSelection] = useState(true);

  if (contentType === 'track') {
    id = useParams().learningTrackId;
  }

  id = contentId || id;

  let contextIdSearchParam = !journeyId && !trackId ? searchParams.get('contextId') : null;

  const removeContextId = () => {
    contextIdSearchParam = null;
    setCompletionContextId(null);
  };

  const handleContextSelect = async (selectedContextId: string) => {
    try {
      await ContextService.validate(selectedContextId, contentType);

      setCompletionContextId(selectedContextId);
    } catch (e) {
      console.log('Error attempting to validate user content', e);

      setToast({
        show: true,
        status: 'error',
        title: t('recompletions.errors.accessDenied'),
      });

      setSearchParams({});

      removeContextId();
    }
  };

  const contexts: { key: string; value: string }[] = [];

  if (journeyId) {
    contexts.push({ key: 'journey', value: journeyId });
  }

  if (trackId) {
    contexts.push({ key: 'track', value: trackId });
  }

  useEffect(() => {
    if (contextIdSearchParam) {
      handleContextSelect(contextIdSearchParam);
    }
  }, [contextIdSearchParam]);

  useEffect(() => {
    if (completionContextId) {
      setNeedsContextSelection(false);
    }
  }, [completionContextId]);

  const completionMutation = useMutation({
    mutationFn: (variables: CompletionMutationVariables) => {
      let payload: CompleteContentPayload = {
        id,
        contentType,
        contexts,
        contextId: completionContextId,
        parentContextId,
      };

      if (variables.signature && variables.signedAgreementTextId) {
        payload = {
          ...payload,
          signature: variables.signature,
          signedAgreementTextId: variables.signedAgreementTextId,
        };
      }

      return ContentService.completeContent(payload);
    },

    onSuccess: () => {
      queryClient.setQueryData(['content-satisfied', contentType, completionContextId], {
        satisfied: true,
      });

      if (onCompleteContent) {
        onCompleteContent();
      }
    },
    onError: (error) => {
      console.log(`Error completing content`, error);
    },
  });

  const Content = content;

  const { data: contentSatisfiedData } = useQuery({
    enabled: !!completionContextId && (contentType === 'article' || contentType === 'file'),
    queryKey: ['content-satisfied', contentType, completionContextId],
    queryFn: () => ContextService.getContentSatisfied(contentType, completionContextId),
  });

  return (
    <>
      <ContextSelector
        contentType={contentType}
        contentId={id}
        handleContextSelect={handleContextSelect}
        enabled={!selectorDisabled}
        initialContextId={contextIdSearchParam}
        parentContextId={parentContextId}
        contexts={contexts}
      />

      <Content
        key={completionContextId}
        contentId={id}
        needsContextSelection={needsContextSelection}
        sendCompleteContent={completionMutation.mutateAsync}
        runCompletionSideEffects={onCompleteContent}
        contextId={completionContextId}
        trackId={trackId}
        journeyId={journeyId}
        journeyNavigateAction={journeyNavigateAction}
        contentSatisfied={contentSatisfiedData?.satisfied}
      />
    </>
  );
}
