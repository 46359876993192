import {
  createAgreementText as createAgreementTextMutation
} from "@/client/services/api/graphql/queries/create/content/agreementText";

import { graphQLClient } from "@/client/services/api/clients/graphqlClient";

export async function createAgreementText(
  text: string,
  companyDefault: boolean
): Promise<string>{
  const response: any = await graphQLClient.request(
    createAgreementTextMutation, { text, companyDefault }
  );

  return response.createAgreementText.id;
};

const AgreementTextService = {
  createAgreementText
}

export default AgreementTextService;