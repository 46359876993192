/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import {
  ContentID,
  UserManagerAssignedContent,
} from '@/client/types/admin/user-manager/UserManagerAssignedContent';
import { IoCaretDown, IoCaretUp } from 'react-icons/io5';
import { MdCheck, MdClose, MdEdit, MdMailOutline } from 'react-icons/md';
import { differenceInCalendarDays, differenceInSeconds } from 'date-fns';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import AssignmentModal from '../assignments/AssignmentModal';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import TableActionsMenuNew from '../menus/TableActionsMenuNew';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';
import { learnV4ApiClient } from '@/client/services/api/clients/learnV4ApiClient';
import { useFormatDate } from '@/client/services/hooks/date-and-time/useFormatDate';
import { useParams } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface UserAssignedContentProps {
  totalAssignmentCount: number | undefined;
}

export default function UserAssignedContent({ totalAssignmentCount }: UserAssignedContentProps) {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setToast } = useToastStore();
  const [sortByOverdue, setSortByOverdue] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<UserManagerAssignedContent | null>(null);
  const [toMarkComplete, setToMarkComplete] = useState<UserManagerAssignedContent | null>(null);
  const [selectedAssigment, setSelectedAssignment] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);

  const getUserManagerAssignedContent = async (
    options: { pageIndex: number; pageSize: number },
    overdue: boolean,
    userId: string | undefined,
  ): Promise<{ rows: UserManagerAssignedContent[]; pageCount: number }> => {
    const response = await learnV4ApiClient.post(
      `admin-assignments/search`,
      { overdue, users: [userId] },
      {
        params: {
          'assigned_content.content_id-populate': 'name,title,type,archived',
          'assignee-populate': 'first_name,last_name,full_name,email',
          fields:
            'assignee,completed,incomplete,completed_date,due_date,assigned_content,completed_content,createdAt',
          page: options.pageIndex + 1,
          per_page: options.pageSize,
          sort: 'createdAt,-1',
        },
      },
    );

    // Filter out assignments that return null for content_id
    const filtered = response.data
      .map((assignment: UserManagerAssignedContent) => {
        const filteredAssignment = assignment.assigned_content.filter(
          (content) => content.content_id !== null,
        );

        if (filteredAssignment.length === 0) return null;

        return {
          ...assignment,
          assigned_content: filteredAssignment,
        };
      })
      .filter((assignment: UserManagerAssignedContent) => assignment !== null);

    return {
      rows: filtered,
      pageCount: totalAssignmentCount ? Math.ceil(totalAssignmentCount / options.pageSize) : 1,
    };
  };

  const setContentTypeUrl = (assignment: ContentID) => {
    let type = 'content';

    switch (assignment.type) {
      case 'article':
        type = 'articles';
        break;
      case 'video':
        type = 'videos';
        break;
      case 'files':
        type = 'files';
        break;
      case 'track':
        type = 'tracks';
        break;
      case 'scorm':
        type = 'scorm';
        break;
      case 'imported_content':
        type = 'imported-content';
        break;
      case 'journey':
        type = 'journeys';
        break;
      case 'workshop':
        type = 'workshops';
        break;
      case 'multi_session_workshop':
        type = 'multi-session-workshops';
        break;
      default:
        break;
    }

    return `/pvt/#/edit/${type}/${assignment._id}`;
  };

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, refetch } = useQuery({
    queryKey: ['user-manager-assigned-content', fetchDataOptions, id, sortByOverdue],
    queryFn: () => getUserManagerAssignedContent(fetchDataOptions, sortByOverdue, id),
  });

  const remindUser = useMutation({
    mutationFn: async (assignment: UserManagerAssignedContent) => {
      await learnApiClient.post(`assignments/reminder/${assignment._id}`);
    },
    onSuccess: () => {
      refetch();

      setToast({
        show: true,
        status: 'success',
        title: t('assignmentsManager.reminderSuccess'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('assignmentsManager.reminderError'),
      }),
  });

  const removeAssignment = useMutation({
    mutationFn: async (assignment: UserManagerAssignedContent | null) => {
      if (!assignment) return;

      await learnApiClient.delete(`assignments/${assignment._id}`);
    },
    onSuccess: () => {
      setToDelete(null);
      refetch();

      setToast({
        show: true,
        status: 'success',
        title: t('assignmentsManager.removeSuccess'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('assignmentsManager.removeError'),
      }),
  });

  const markManuallyComplete = async (idToComplete: string | undefined) => {
    if (!selectedDate) return;

    const response = await learnApiClient.post(`assignments/${idToComplete}/manual-complete`, {
      completedDate: new Date(selectedDate),
    });

    return response.data;
  };

  const manuallyMarkComplete = useMutation({
    mutationFn: (assignmentId: string | undefined) => markManuallyComplete(assignmentId),
    onSuccess: () => {
      setToMarkComplete(null);
      refetch();

      setToast({
        show: true,
        status: 'success',
        title: t('assignment.manualCompletion.completed'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('assignment.manualCompletion.error'),
      }),
  });

  const generateStatusTag = (assignment: UserManagerAssignedContent) => {
    let onTime = true;

    if (assignment.completed) {
      if (assignment.due_date && assignment.completed_date) {
        onTime =
          differenceInSeconds(new Date(assignment.due_date), new Date(assignment.completed_date)) >
          0;
      }

      return (
        <Tag colorScheme={onTime ? 'green' : 'orange'}>
          {onTime
            ? t('assignmentsManager.completed_on_time')
            : t('assignmentsManager.completed_past_due')}
        </Tag>
      );
    }

    if (assignment.due_date) {
      onTime = differenceInCalendarDays(new Date(assignment.due_date), new Date()) > 0;
    }

    return (
      <Tag colorScheme={onTime ? 'yellow' : 'red'}>
        {onTime
          ? t('assignmentsManager.pending_on_time')
          : t('assignmentsManager.pending_past_due')}
      </Tag>
    );
  };

  const columnHelper = createColumnHelper<UserManagerAssignedContent>();
  const columns = useMemo<ColumnDefBase<UserManagerAssignedContent, any>[]>(
    () => [
      columnHelper.accessor('assignee.full_name', {
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: () => <span>{t('assignmentsManager.assignee')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('assigned_content', {
        cell: (info) => (
          <Stack>
            {info.row.original.assigned_content.map((content) => (
              <Link key={content._id} href={setContentTypeUrl(content.content_id)}>
                {content.content_id.title || content.content_id.name}
              </Link>
            ))}
          </Stack>
        ),
        header: () => <span>{t('assignmentsManager.content')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'status',
        cell: (info) => generateStatusTag(info.row.original),
        header: () => (
          <HStack>
            <span>{t('assignmentsManager.status')}</span>
            {sortByOverdue ? (
              <IoCaretDown onClick={() => setSortByOverdue(!sortByOverdue)} />
            ) : (
              <IoCaretUp onClick={() => setSortByOverdue(!sortByOverdue)} />
            )}
          </HStack>
        ),
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => <Text>{formatDate(new Date(info.getValue()), 'baseWithTime')}</Text>,
        header: () => <span>{t('assignmentsManager.assigned_on')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'dueDate',
        cell: (info) => (
          <Text>
            {info.row.original.due_date
              ? formatDate(new Date(info.row.original.due_date), 'baseWithTime')
              : '----'}
          </Text>
        ),
        header: () => <span>{t('assignmentsManager.due_date')}</span>,
      }),
      columnHelper.display({
        id: 'completedDate',
        cell: (info) => (
          <Text>
            {info.row.original.completed_date
              ? formatDate(new Date(info.row.original.completed_date), 'baseWithTime')
              : '----'}
          </Text>
        ),
        header: () => <span>{t('assignmentsManager.completed_on')}</span>,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <TableActionsMenuNew
            isDisabled={!!info.row.original.completed_date}
            actions={[
              {
                icon: <Icon as={MdEdit} color="brand.button" />,
                label: t('global.actions.edit'),
                enabled: true,
                onClick: () => setSelectedAssignment(info.row.original._id),
              },
              {
                icon: <Icon as={MdMailOutline} />,
                label: t('manage.users.assigned_remindUser'),
                enabled: true,
                onClick: () => remindUser.mutate(info.row.original),
              },
              {
                icon: <Icon as={MdCheck} color="brand.green" />,
                label: t('adminUserAssigned.markAsCompleted'),
                enabled: true,
                onClick: () => setToMarkComplete(info.row.original),
              },
              {
                icon: <Icon as={MdClose} color="brand.red" />,
                label: t('adminUserAssigned.remove_assignment'),
                enabled: true,
                onClick: () => setToDelete(info.row.original),
              },
            ]}
          />
        ),
      }),
    ],
    [sortByOverdue],
  );

  return (
    <Box
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1);"
    >
      <ManuallyPaginatedTable
        columns={columns}
        queryData={data}
        pagination={pagination}
        setPagination={setPagination}
      />

      <Modal size="2xl" isOpen={!!toDelete} onClose={() => setToDelete(null)}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <VStack paddingY={12}>
              <Text>{t('deleteAssignment.remove_confirm')}</Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup size="sm">
              <Button variant="adminPrimary" onClick={() => removeAssignment.mutate(toDelete)}>
                {t('global.action.remove')}
              </Button>

              <Button variant="adminError" onClick={() => setToDelete(null)}>
                {t('global.label.cancel')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size="2xl" isOpen={!!toMarkComplete} onClose={() => setToMarkComplete(null)}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody>
            <VStack paddingY={12} spacing={4}>
              <Text fontSize="md" fontWeight="semibold">
                {t('track.manualCompletion.heading')}
              </Text>

              <Text>{t('track.manualCompletion.cannotBeUndone')}</Text>

              <Text>{t('track.manualCompletion.areYouSure')}</Text>

              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup size="sm">
              <Button
                variant="adminPrimary"
                onClick={() => manuallyMarkComplete.mutate(toMarkComplete?._id)}
              >
                {t('global.actions.confirm')}
              </Button>

              <Button variant="adminError" onClick={() => setToMarkComplete(null)}>
                {t('global.label.cancel')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AssignmentModal
        assignmentId={selectedAssigment}
        onClose={() => setSelectedAssignment(null)}
      />
    </Box>
  );
}
