import { ContextChoices, ContextData } from '@/client/types/Context';

import { ContentTypes } from './graphql/gql/graphql';
import { getContentSatisfiedQuery } from './graphql/queries/contentContext/getContentSatisfiedQuery';
import { graphQLClient } from './clients/graphqlClient';
import { learnApiClient } from './clients/learnApiClient';

const getContextChoices = async (
  contentType: string | undefined,
  contentId: string | undefined,
): Promise<ContextChoices[]> => {
  const response = await learnApiClient.get(`contexts/${contentId}?contentType=${contentType}`);
  const contexts: ContextData[] = response.data.filter(
    (context: ContextData) => !context.completed_date,
  );
  const contextChoices: ContextChoices[] = [];
  for (const context of contexts) {
    // not sure how we should resolve display when we have multiple contexts, will need input from UX
    // for now just display data from the outer context (first in the list)
    const innerContext = context.contexts && context.contexts[0];
    // TODO update when we allow for more selectable contexts
    if (innerContext?.context_type === 'assignment') {
      contextChoices.push({
        contextId: context.id,
        creationDate: context.creation_date,
        contextDueDate: innerContext?.context_due_date,
        startDate: context.start_date,
        contextPercentCompleted: innerContext?.context_percent_completed,
        contextType: innerContext?.context_type,
      });
    }
  }
  return contextChoices;
};

const getOrCreateContext = async (
  contentType: string | undefined,
  contentId: string | undefined,
  contexts: { key: string; value: string }[],
  parentContextId: string | undefined,
  initialContextId: string | null,
): Promise<string> => {
  const response = await learnApiClient.post(`contexts`, {
    contentId,
    contentType,
    contexts,
    parentContextId,
    initialContextId
  });
  return response.data.id;
};

// Will throw error if unsuccessful, no need for return value
const validate = async (contextId: string | undefined, contentType: string | undefined) => {
  await learnApiClient.post(`contexts/validate`, {
    contextId,
    contentType,
  });
};

const contentTypeEnumMap: { [key: string]: ContentTypes } = {
  article: ContentTypes.Article,
  file: ContentTypes.File,
};

const getContentSatisfied = async (contentType: string, contextId: string | null) => {
  const data: any = await graphQLClient.request(getContentSatisfiedQuery, {
    contentType: contentTypeEnumMap[contentType],
    contextId,
  });

  return data.getUserContent;
};

const ContextService = {
  getContextChoices,
  getOrCreateContext,
  validate,
  getContentSatisfied,
};

export default ContextService;
