import {
  Button,
  Collapse,
  Stack,
  Flex,
  Text,
  Textarea,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useAuthStore } from '@/client/services/state/authStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { useMutation } from '@tanstack/react-query';
import CreateFormGroup from '../../../shared/layout/CreateFormGroup';
import CreateSwitchContainer from '../../../shared/layout/CreateSwitchContainer';
import AgreementTextService from '@/client/services/api/admin/content-authoring/AgreementTextService';

export default function AuthoringEsignatureContainer() {
  const { watch, setValue } = useFormContext();
  const requireSignature = watch('signatureRequired');
  const contentSignatureText = watch('signatureAgreementText');
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newSignatureAgreementText, setNewSignatureAgreementText] = useState(
    contentSignatureText?.text || company.default_esignature_agreement_text?.text || '',
  );
  const [isNewCompanyDefaultText, setIsNewCompanyDefaultText] = useState(false);

  const handleAddTextToContent = (id: string, text: string) => {
    setValue('signatureAgreementText', { id, text });
    setEditModalOpen(false);
  };

  const createAgreementText = useMutation({
    mutationFn: ({ text, companyDefault }) =>
      AgreementTextService.createAgreementText(text, companyDefault),
    onSuccess: (id: string, variables: any) => {
      handleAddTextToContent(id, variables.text);
    },
    onError: (e) => {
      setToast({
        show: true,
        status: 'error',
        title: t('esignature.adminModal.error'),
      });
      setEditModalOpen(false);
    },
  });

  const handleSaveNewText = () => {
    createAgreementText.mutate({
      text: newSignatureAgreementText,
      companyDefault: isNewCompanyDefaultText,
    });
  };

  const handleCancelEditText = () => {
    setNewSignatureAgreementText(
      contentSignatureText?.text || company.default_esignature_agreement_text?.text || '',
    );
    setEditModalOpen(false);
  };

  useEffect(() => {
    if (requireSignature) {
      // If no signature agreement text is set, prompt user to add text
      if (!contentSignatureText?.text && !company.default_esignature_agreement_text?.text) {
        setEditModalOpen(true);
      }
      // If no signature agreement text is set on the content, but there is a company default, add it to the content
      if (!contentSignatureText?.text && company.default_esignature_agreement_text?.text) {
        const { _id: id, text } = company.default_esignature_agreement_text;
        handleAddTextToContent(id, text);
      }
    }
  }, [requireSignature]);

  return (
    <CreateFormGroup>
      <Stack>
        <CreateSwitchContainer
          name="signatureRequired"
          label={t('esignature.require.label')}
          helpText={t('esignature.require.helpText')}
        />
        <Collapse in={requireSignature} animateOpacity>
          <Flex direction="row" alignItems="center" justifyContent="space-between">
            {contentSignatureText?.text || company.default_esignature_agreement_text?.text ? (
              <Flex direction="row">
                <Box alignSelf="flex-start">"</Box>
                <Text fontSize="sm" maxW={{base: "sm", xl: "lg"}} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {contentSignatureText?.text || company.default_esignature_agreement_text?.text}
                </Text>
                <Box alignSelf="flex-start">"</Box>
              </Flex>
            ) : (
              <Text fontStyle="italic" fontSize="sm">
                {t('esignature.addNewText.prompt')}
              </Text>
            )}
            <Text
              textDecoration="underline"
              color="brand.gold.100"
              cursor="pointer"
              onClick={() => setEditModalOpen(true)}
            >
              {t('esignature.require.edit')}
            </Text>
          </Flex>
        </Collapse>
      </Stack>
      <Modal isOpen={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <ModalOverlay />
        <ModalContent paddingX={2} paddingY={6} marginTop={28} height="md" minWidth="35%">
          <ModalHeader>
            <Text variant="createHeading">{t('esignature.adminModal.editText')}</Text>
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" height="100%" justifyContent="space-between">
              <Textarea
                width="100%"
                height="2xs"
                resize="none"
                value={newSignatureAgreementText}
                onChange={(event) => setNewSignatureAgreementText(event.target.value)}
              />
              <Flex direction="row" justifyContent="space-between">
                <Checkbox
                  checked={isNewCompanyDefaultText}
                  onChange={(event) => setIsNewCompanyDefaultText(!!event.target.checked)}
                >
                  {t('esignature.adminModal.checkIsCompanyDefault')}
                </Checkbox>
                <Flex direction="row" gap={4} alignSelf="flex-end">
                  <Button variant="cancel" onClick={() => handleCancelEditText()}>
                    {t('esignature.adminModal.cancel')}
                  </Button>
                  <Button
                    variant="solid"
                    disabled={!newSignatureAgreementText.length}
                    onClick={() => handleSaveNewText()}
                  >
                    {t('esignature.adminModal.save')}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </CreateFormGroup>
  );
}
